import React, { useEffect, useState } from 'react'
import { withRouterProps } from '../../utils/with-router-props'
import { connect } from 'react-redux'
// import BlockMedia from '../../shared/blocks/block-media'

import EditableMarkdown from '../../utils/editable-markdown'
import { apiRemoveAction, manageApiData } from '../../../actions/api'
import { ulid } from 'ulid'
import ContentBlock from './content-block'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const mapStateToProps = (state, ownProps) => {
    return {
        currentStep: state.manualSteps.find(step => step.id === ownProps.params.stepId),
        currentSubstep: state.manualSubsteps.find(substep => substep.id === ownProps.params.substepId)
    }
}

const BuilderPageContent = ({ dispatch, currentStep, currentSubstep }) => {
    // const [addingContentType, setAddingContentType] = useState('')
    const [ renderIdx, setRenderIdx ] = useState(0)

    useEffect(() => {
        setRenderIdx(renderIdx + 1)
    }, [currentSubstep])

    const renderContentBlocks = () => {
        // html
        // image (refernce media)
        // action
        // checklist
        // upload image
        // take picture
        let allContentBlocks = []
        currentSubstep.content.forEach((item, idx) => {
            allContentBlocks.push(<ContentBlock key={item.id} type={item.type} idx={idx} item={item} substep={currentSubstep} />)
            // switch (item.type) {
            // case 'html':
            //     allContentBlocks.push(<ContentBlock key={item.id} item={item} />)
            //     // return <div key={item.id} dangerouslySetInnerHTML={{ __html: item.content }} />
            // case 'media':
            //     // TODO: Switch case on media type
            //     let media = currentSubstep?.referenceMedia.find(m => m.id === item.mediaId)
            //     return <BlockMedia key={item.id} item={media} />
            // default:
            //     return <div key={item.id}>TBD: Other item types</div>
            // }
        })
        return allContentBlocks
    }

    const saveSubstepTitle = (newTitle) => {
        const substepData = { ...currentSubstep, title: newTitle }
        dispatch(manageApiData(substepData))
    }

    const addNewContentBlock = (contentType) => {
        let existingContent = currentSubstep?.content ? structuredClone(currentSubstep.content) : []
        let newContentBlock = {
            id: ulid().toLowerCase(),
            dOrder: existingContent.length + 1,
            type: contentType
        }
        if (contentType === 'html') {
            newContentBlock.content = ''
        } else if (contentType === 'media') {
            newContentBlock.mediaId = null
        } else if (contentType === 'action') {
            newContentBlock.actionId = null
        }
        existingContent.push(newContentBlock)
        dispatch(manageApiData({ ...currentSubstep, content: existingContent }))
    }

    const handleDelete = (evt) => {
        evt.stopPropagation()
        dispatch(apiRemoveAction(currentSubstep))
    }

    const renderContentTitle = () => {
        return (
            <div className="with-delete-btn">
                <h3>
                    <EditableMarkdown key={renderIdx} contentValue={currentSubstep.title} saveFX={saveSubstepTitle} />
                </h3>
                <span className="delete-btn">
                    <span onClick={handleDelete}>
                        <FontAwesomeIcon icon="trash-alt" />
                    </span>
                </span>
            </div>
        )
    }

    const handleAddContentType = (contentType) => {
        if (contentType !== '') {
            addNewContentBlock(contentType)
        }
        // setAddingContentType('')
    }

    const renderAddContentBlock = () => {
        return (
            <div className="content-block previewing">
                <div className="content-actions">
                    <div onClick={() => handleAddContentType('html')}>Add Text</div>
                    <div onClick={() => handleAddContentType('media')}>Add Media</div>
                    <div onClick={() => handleAddContentType('action')}>Add Action</div>
                </div>
                {/* <select
                    id="content-selector"
                    value={addingContentType}
                    style={{ padding: '2rem' }}
                    onChange={handleSelectContentType}>
                    <option>Add a Content Block</option>
                    <option value="html">HTML</option>
                    <option value="media">Media</option>
                    <option value="action">Action</option>
                </select> */}
            </div>
        )
    }

    if (!currentSubstep) {
        return (
            <div className="builder-page-content">
                No instructions found, please select an item in the sidebar menu.
            </div>
        )
    }

    return (
        <div className="builder-page-content">
            {renderContentTitle()}
            {currentSubstep?.content && renderContentBlocks() }
            {renderAddContentBlock()}
        </div>
    )
}

export default withRouterProps(connect(mapStateToProps)(BuilderPageContent))
