import React from 'react'
import { connect } from 'react-redux'
import { Routes, Route } from 'react-router-dom'
import { withRouterProps } from '../../utils/with-router-props'
import { routes, nestedRoutePath } from '../../../constants/routes'

import TeamList from './list'
import CreateUser from './create'
import UserView from './view'


const TeamIndex = () => {
    return (
        <>
            <Routes>
                <Route path={nestedRoutePath(routes.appTeamIndex.path, routes.appCreateUser.path, true)} element={<CreateUser />} />
                <Route path={nestedRoutePath(routes.appTeamIndex.path, routes.appUserView.path, true)} element={<UserView />} />
                <Route index element={<TeamList />} />
            </Routes>
        </>
    )
}

export default withRouterProps(connect()(TeamIndex))
