import React from 'react'

const PageHeader = ({ extraClass, title }) => {
    return (
        <div className={`page-header ${extraClass || ''}`}>
            {title}
        </div>
    )
}

export default PageHeader
