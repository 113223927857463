import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { apiResourceEndpoint, buildRoutePath, routes } from '../../../constants/routes'
import { fetchApiDataIfNeeded } from '../../../actions/api'

const mapStateToProps = (state) => {
    return {
        manuals: state.manuals
    }
}

const ListManuals = ({ dispatch, manuals }) => {
    useEffect(() => {
        dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('admin/manuals', 'LIST')))
    }, [dispatch])

    const renderManuals = () => {
        let allManuals = []
        manuals.forEach(manual => {
            allManuals.push(
                <tr key={manual.id} className="manual-list-item">
                    <td>{manual.title}</td>
                    <td>{manual.version}</td>
                    <td>
                        <Link to={buildRoutePath(routes.adminManualBuilderIndex.path, { manualId: manual.id, versionId: manual.version })}>
                            Build
                        </Link>
                        &nbsp;Clone
                    </td>
                    {/* <div>
                        <Link to={buildRoutePath(routes.adminManualsView.path, { manualId: manual.id, versionId: manual.version })} className="btn">
                            View
                        </Link>
                    </div> */}
                </tr>
            )
        })
        return (
            <table className="table">
                <thead>
                    <tr>
                        <th>Title</th>
                        <th>Version</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {allManuals}
                </tbody>
            </table>
        )
    }

    return (
        <div className="content">
            <div className="page-title">
                <h1>Manuals</h1>
                <Link to={routes.adminManualsCreate.path}>
                    + Create New Manual
                </Link>
            </div>
            {renderManuals()}
            {/* <div className="footer-btns">
                <Link to={routes.adminManualsCreate.path} className="btn blue main">
                    Create New Manual
                </Link>
            </div> */}
        </div>
    )
}

export default connect(mapStateToProps)(ListManuals)
