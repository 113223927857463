import { generatePath } from 'react-router-dom'

export const API_METHODS = { POST: 'POST', GET: 'GET', PUT: 'PUT', DELETE: 'DELETE' }

export const routes = {
    publicIndex: { path: '/', label: 'Home' },
    privacyPolicy: { path: '/privacy-policy/', label: 'Privacy Policy' },
    router: { path: '/router/', label: '' },

    authLogin: { path: '/login/', label: 'Login' },
    authLogout: { path: '/logout/', label: 'Logout' },
    authRegister: { path: '/register/', label: 'Register' },
    authPasswordHelp: { path: '/password-help/', label: 'Password Help' },
    authPasswordReset: { path: '/password-reset/', label: 'Password Reset' },
    authHandler: { path: '/auth-handler/', label: '' },
    authWelcome: { path: '/welcome/', label: 'Welcome' },

    appIndex: { path: '/app/', label: 'Dashboard' },
    appTrucksView: { path: '/app/trucks/:truckId/', label: 'Trucks' },
    appTrucksStep: { path: '/app/trucks/:truckId/step/', label: 'Step' },

    appInstallationView: { path: '/app/installation/:truckId/', label: 'Installation' },

    appSettingsIndex: { path: '/app/settings/', label: 'Settings' },
    appUserIndex: { path: '/app/user/', label: 'Profile' },
    appTeamIndex: { path: '/app/team/', label: 'Team' },
    appCreateUser: { path: '/app/team/create/', label: 'Create User' },
    appUserView: { path: '/app/team/:userId/', label: 'View User' },

    adminIndex: { path: '/admin/', label: 'Admin' },
    adminAccountIndex: { path: '/admin/account/', label: 'Account' },

    adminTrucksIndex: { path: '/admin/trucks/', label: 'Trucks' },

    adminManualBuilderIndex: { path: '/admin/manuals/build/:manualId/:versionId/', label: 'Builder' },
    adminManualBuilderStep: { path: '/admin/manuals/build/:manualId/:versionId/:stepId/', label: 'Builder: Step' },
    adminManualBuilderSubstep: { path: '/admin/manuals/build/:manualId/:versionId/:stepId/:substepId', label: 'Builder: Substep' },

    adminManualsIndex: { path: '/admin/manuals/', label: 'Manuals' },
    adminManualsCreate: { path: '/admin/manuals/create/', label: 'Create' },
    // adminManualsView: { path: '/admin/manuals/:manualId/:versionId/', label: 'View' },
    // adminManualsStepView: { path: '/admin/manuals/:manualId/:versionId/step/:stepId/', label: 'Step' },
    adminManualsStep: { path: '/admin/manuals/:manualId/:versionId/:stepId/', label: 'Step' },
    adminManualsView: { path: '/admin/manuals/:manualId/:versionId/', label: 'View' },
    adminManualsSubstep: { path: '/admin/manuals/:manualId/:versionId/:stepId/:substepId', label: 'Substep' },

    adminAccountsIndex: { path: '/admin/accounts/', label: 'Accounts' },

    createUser: { path: '/create-user/', label: '' }
}

export const apiResourceEndpoint = (resource, action, id = null, extraPath = '') => {
    let routeObj = {
        url: `/${resource}/`,
        method: API_METHODS.GET
    }
    // adjust the route
    if (action === 'UPDATE' || action === 'VIEW' || action === 'DELETE') {
        routeObj.url = routeObj.url + `${id}/`
    }
    // adjust the medthod
    if (action === 'CREATE' || action === 'UPDATE') {
        routeObj.method = API_METHODS.POST
    } else if (action === 'DELETE') {
        routeObj.method = API_METHODS.DELETE
    }
    // do we need to append any extra stuff to the url
    if (extraPath !== '') {
        routeObj.url = routeObj.url + extraPath + '/'
    }
    return routeObj
}

export const nestedRoutePath = (parent, route, isParent = false) => {
    let nestedRoute = route.substr(parent.length)
    if (isParent) {
        nestedRoute = nestedRoute + '*'
    }
    return nestedRoute
}

export const buildRoutePath = (route, params = {}) => {
    return generatePath(route, params)
}
