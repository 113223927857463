import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import { routes } from '../../constants/routes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const AdminNav = () => {
    const [ isOpen, setIsOpen ] = useState(false)

    const renderMenuOverlay = () => {
        return (
            <div className={`menu-overlay ${isOpen ? 'active' : ''}`}>
                <div className="closer" onClick={() => setIsOpen(false)}>
                    <FontAwesomeIcon icon="times" />
                </div>
                <div className="menu">
                    <div className="links">
                        {/* <Link to={routes.publicIndex.path}>Home</Link> */}
                        <Link onClick={() => setIsOpen(false)} to={routes.adminIndex.path}>Admin Dashboard</Link>
                        <Link onClick={() => setIsOpen(false)} to={routes.adminTrucksIndex.path}>Trucks</Link>
                        <Link onClick={() => setIsOpen(false)} to={routes.adminManualsIndex.path}>Manuals</Link>
                        <Link onClick={() => setIsOpen(false)} to={routes.adminAccountsIndex.path}>Accounts</Link>
                        <Link onClick={() => setIsOpen(false)} to={routes.appIndex.path}>App</Link>
                        <Link onClick={() => setIsOpen(false)} to={routes.authLogout.path}>Logout</Link>
                    </div>
                    <div className="logo-wrapper">
                        <img src="/imgs/optimus-gear-only-white.svg" alt="Optimus Technologies" height="50" />
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            <div className={`nav ${isOpen ? 'open' : ''}`}>
                <div className="menu-wrapper">
                    <span className="hamburger" onClick={() => setIsOpen(!isOpen)}>
                        <FontAwesomeIcon icon="bars" />
                    </span>
                </div>
            </div>
            { renderMenuOverlay() }
        </>
    )
}

export default AdminNav
