import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Editable, useEditor } from '@wysimark/react'
import { manageApiData } from '../../../actions/api'
import Markdown from 'react-markdown'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ContentBlock = ({ dispatch, type, item, idx, substep }) => {
    console.log(item)
    const [ inEdit, setInEdit ] = useState(false)
    const [ markdown, setMarkdown ] = useState(item.content)
    const editor = useEditor({})

    const handleEditToggle = () => {
        if (!inEdit) {
            setInEdit(true)
        }
    }

    const handleSave = () => {
        let newContent = { ...item, content: markdown }
        let existingContentList = structuredClone(substep.content)
        existingContentList[idx] = newContent
        dispatch(manageApiData({ ...substep, content: existingContentList }))
        setInEdit(false)
    }

    const handleDelete = (evt) => {
        evt.stopPropagation()
        let existingContentList = structuredClone(substep.content)
        existingContentList.splice(idx, 1)
        dispatch(manageApiData({ ...substep, content: existingContentList }))
    }

    const renderEditMode = () => {
        if (!inEdit) {
            return null
        }
        return (
            <>
                <Editable editor={editor} value={markdown} onChange={setMarkdown} />
                <span className="btn small" style={{ marginTop: '1rem', width: '20%', borderColor: 'rgb(212, 212, 216)' }} onClick={handleSave}>Save</span>
            </>
        )
    }

    const renderPreviewMode = () => {
        if (inEdit) {
            return null
        }
        if (item.content === '') {
            return <em>No content yet. Click to add.</em>
        }
        return (
            <Markdown>{item.content}</Markdown>
        )
    }

    return (
        <div className={`content-block ${type} ${inEdit ? 'editing' : 'previewing'}`} onClick={handleEditToggle}>
            {renderEditMode()}
            {renderPreviewMode()}
            <div className="delete-btn">
                <span onClick={handleDelete}>
                    <FontAwesomeIcon icon="trash-alt" />
                </span>
            </div>
        </div>
    )
}

export default connect()(ContentBlock)
