import React, { useState } from 'react'
import { connect } from 'react-redux'

import { updateAuthPassword } from '../../../actions/auth'

import SHSForm from '../../utils/shs-form'
import InputElement from '../../utils/input'
import VerifyPassword from '../../shared/forms/verify-password'

const ManageAuth = ({ dispatch, fireauth }) => {
    const [inEdit, setInEdit] = useState(false)
    const [allValid, setAllValid] = useState(false)

    const handleValidationChange = (isValid) => {
        setAllValid(isValid)
    }

    const successFX = () => {
        window.alert('Password updated successfully')
        setInEdit(false)
    }

    const submitFX = (formData, formId) => {
        dispatch(updateAuthPassword(fireauth, formData, formId))
    }

    const renderForm = () => {
        return (
            <SHSForm submitFX={submitFX} successFX={successFX} buttonLabel="Update Password" buttonDisabled={!allValid} showCancel={true} cancelAction={() => setInEdit(false)}>
                <InputElement
                    type="password"
                    label="Existing Password"
                    id="existingPassword" />
                <VerifyPassword onValidationChange={handleValidationChange} />
            </SHSForm>
        )
    }

    const renderView = () => {
        return (
            <>
                <span className="btn grey" onClick={() => setInEdit(true)}>Change Password</span>
            </>
        )
    }

    return (
        <div className="manage-auth">
            {inEdit ? renderForm() : renderView()}
        </div>
    )
}


export default connect()(ManageAuth)
