import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import initStore from './store/configure-store'

import { initFirebase } from './firebase/firebase'

import App from './components/app'

const store = initStore({})
const firebaseApp = initFirebase({})

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <Provider store={store}>
        <BrowserRouter>
            <App firebaseApp={firebaseApp} />
        </BrowserRouter>
    </Provider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
