import { createSlice } from '@reduxjs/toolkit'
import * as helpers from './helpers'

const genericSlice = (sliceName, iState = [], defaultPk = 'id') => {
    return createSlice({
        name: sliceName,
        initialState: iState,
        reducers: {},
        extraReducers: (builder) => {
            builder
                .addCase(`${sliceName}/singleAction`, (state, action) => {
                    const model = action.data
                    if (Array.isArray(iState)) {
                        const objectIdx = helpers.getObjectIndex(state, model[defaultPk], defaultPk)
                        if (objectIdx === -1) {
                            state.push(model)
                        } else {
                            state[objectIdx] = model
                        }
                    } else {
                        if ('_key' in model) {
                            // TODO: revisit this assumption as we clean up the database...
                            // delete model._key
                        }
                        if ('_related' in model) {
                            delete model._related
                        }
                        return model
                    }
                })
                .addCase(`${sliceName}/listAction`, (state, action) => {
                    action.data.forEach(model => {
                        const objectIdx = helpers.getObjectIndex(state, model[defaultPk], defaultPk)
                        if (objectIdx === -1) {
                            state.push(model)
                        } else {
                            state[objectIdx] = model
                        }
                    })
                    return state
                })
                .addCase(`${sliceName}/removeItem`, (state, action) => {
                    return state.filter(item => item[defaultPk] !== action.data[defaultPk])
                })
                .addCase('KILL_STATE', (state, action) => {
                    return iState
                })
        }
    }).reducer
}

export default genericSlice
