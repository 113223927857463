export const INITIAL_TRUCK_DATA = [
    {
        id: 1,
        name: 'Truck Name 123',
        hasError: true,
        status: 'Rejected, please review',
        currentStep: 4,
        totalSteps: 10,
        steps: [
            {
                group: 1,
                topic: 'Install',
                sectionTotal: 6,
                complete: 6,
                isActive: true
            },
            {
                group: 2,
                topic: 'Route Electrical Lines',
                sectionTotal: 5,
                complete: 2,
                isActive: true,
                hasError: true,
                status: 'Rejected, please review'
            },
            {
                group: 2,
                topic: 'Test & Analyze',
                sectionTotal: 5,
                complete: 2,
                isActive: false,
                hasError: false
            },
            {
                group: 3,
                topic: 'Finalize',
                sectionTotal: 2,
                complete: 0,
                isActive: false
            }
        ]
    },
    {
        id: 2,
        name: 'Truck Name 456',
        hasError: false,
        status: 'Installation in Progress',
        currentStep: 9,
        totalSteps: 10,
        steps: [
            {
                group: 1,
                topic: 'Install',
                sectionTotal: 6,
                complete: 4,
                isActive: true
            },
            {
                group: 2,
                topic: 'Test & Analyze',
                sectionTotal: 2,
                complete: 0,
                isActive: false
            },
            {
                group: 3,
                topic: 'Finalize',
                sectionTotal: 2,
                complete: 0,
                isActive: false
            }
        ]
    },
    {
        id: 3,
        name: 'Truck Name 789',
        hasError: false,
        status: 'Ready for Live Test',
        currentStep: 9,
        totalSteps: 10,
        steps: [
            {
                group: 1,
                topic: 'Install',
                sectionTotal: 6,
                complete: 6,
                isActive: true
            },
            {
                group: 2,
                topic: 'Test & Analyze',
                sectionTotal: 2,
                complete: 2,
                isActive: true
            },
            {
                group: 3,
                topic: 'Finalize',
                sectionTotal: 2,
                complete: 1,
                isActive: true
            }
        ]
    }
]

export const INITIAL_MANUAL_DATA = [
    {
        id: 'manual_1234',
        title: 'Installation Manual - Volvo VN 62T300',
        version: 1234,
        reference_document_id: 'media_5555',
        groups: [
            {
                id: 1,
                title: 'Group 1',
                phase: 1,
                d_order: 1,
                steps: [
                    {
                        id: 1,
                        d_order: 1,
                        phase: 1,
                        title: 'Swap OEM Tank for Vector Fuel Tank',
                        action_definitions: [
                            {
                                id: 'act_1234',
                                required: true,
                                type: 'acknowledment',
                                title: 'Inspect Existing Components',
                                items: [
                                    {
                                        id: 1,
                                        d_order: 1,
                                        description: 'After removing the tank, inspect the existing brackets, harware, straps, and rubber isolators. Replace any of these components if they show signs of damage.'
                                    }
                                ]
                            }
                        ],
                        substeps: [
                            {
                                id: 1,
                                d_order: 1,
                                title: 'Swap OEM Tank for Vector Fuel Tank: Overview',
                                reference_media: [],
                                description: [
                                    {
                                        id: 1,
                                        d_order: 1,
                                        type: 'html',
                                        content: '<p>The Vector System requires an additional fuel compartment, which was added in the form of a right hand (Ditch Side) tank with 97 gallons of Biodiesel capacity.</p>'
                                    },
                                    {
                                        id: 2,
                                        d_order: 2,
                                        type: 'media',
                                        url: 'https://via.placeholder.com/150'
                                    },
                                    {
                                        id: 3,
                                        d_order: 3,
                                        type: 'html',
                                        content: '<p>General Guidelines<ul><li>Be cautious of flammable liquids during removal and installation of fuel tanks</li><li>Defer to the manufacturer\'s guidelines on drilling into the vehicle frame when installing additional tank brackets.</li></ul></p><p><b>Replacing an Existing Tank</b><ul><li>Follow manufacturer\'s guidelines for OEM tank removal, use the following steps as a guide</li></ul></p>'
                                    }

                                ]
                            },
                            {
                                id: 2,
                                d_order: 2,
                                title: 'Removing the OEM Tank',
                                description: [
                                    {
                                        id: 4,
                                        d_order: 1,
                                        type: 'html',
                                        content: '<ol><li>Identify the Ditch side, 100 gallon OEM tank. Drain all fuel from the OEM tank.</li><li>Turn the qty-2 ball valves located on the rear of the OEM tank to the OFF position. While the ball valves are still connected to the supply and return fuel lines, remove the ball valves from the OEM tank and set them aside. Ensure the valves are still connected to the fuel lines and that no leaking is taking place.</li><li>Disconnect he tank vent line and fuel levels ender connector(if applicable) and set aside for future use.</li><li>Support the weight of the fuel tank in anticipation of loosening the tank straps.</li><li>Loosen tank straps to the brackets.</li><li>Fully disconnect the tank and remove it from the brackets. Set aside and out of the installation area.</li></ol>'
                                    },
                                    {
                                        id: 5,
                                        d_order: 2,
                                        type: 'action',
                                        action_id: 'act_1234'
                                    }
                                ],
                                reference_media: [
                                    {
                                        id: 2,
                                        d_order: 1,
                                        type: 'image',
                                        url: 'https://via.placeholder.com/150'
                                    }
                                ]
                            },
                            {
                                id: 3,
                                d_order: 3,
                                title: 'Mounting the Vector B100 Tank',
                                description: [
                                    {
                                        id: 6,
                                        d_order: 1,
                                        type: 'html',
                                        content: '<ol><li>TBD: Copy in the steps...</li></ol>'
                                    }
                                ],
                                reference_media: [
                                    {
                                        id: 6,
                                        d_order: 1,
                                        type: 'image',
                                        url: 'https://via.placeholder.com/150'
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        id: 2,
                        d_order: 2,
                        phase: 1,
                        title: 'Manifold Bracket Mounting',
                        action_definitions: [
                            {
                                id: 'act_xxx_temp',
                                required: true,
                                type: 'checklist',
                                items: [
                                    {
                                        id: 1,
                                        d_order: 1,
                                        description: 'Drain all fuel from the OEM tank.'
                                    },
                                    {
                                        id: 2,
                                        d_order: 2,
                                        description: 'Turn the qty-2 ball valves located on the rear of the OEM tank to the OFF position.'
                                    }
                                ]
                            }
                        ],
                        steps: [
                            {
                                id: 1,
                                d_order: 1,
                                title: 'Manifold Bracket Mounting: Overview',
                                description: [
                                    {
                                        id: 3,
                                        d_order: 1,
                                        type: 'html',
                                        content: '<p>Secure the manifold mounting bracket to the frame rail based on the instructions below. Always use grade 8, grade 10.9, or equivalent hardware.</p>'
                                    }
                                ],
                                reference_media: []
                            },
                            {
                                id: 2,
                                d_order: 2,
                                title: 'Frame Mounted Manfold Bracket',
                                description: [
                                    {
                                        id: 4,
                                        d_order: 1,
                                        type: 'html',
                                        content: '<ol><li>TBD; Copy in the steps</li></ol>'
                                    }
                                ],
                                reference_media: [
                                    {
                                        id: 1,
                                        url: 'https://via.placeholder.com/150'
                                    }
                                ]
                            },
                            {
                                id: 3,
                                d_order: 3,
                                title: 'Mounting manifold to the bracket',
                                description: [
                                    {
                                        id: 6,
                                        d_order: 1,
                                        type: 'html',
                                        content: '<ol><li>TBD: Copy in the steps...</li></ol>'
                                    }
                                ],
                                reference_media: [
                                    {
                                        id: 6,
                                        url: 'https://via.placeholder.com/150'
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        id: 3,
                        d_order: 2,
                        phase: 1,
                        title: 'Manifold, Control Unit, and PDM Mounting',
                        action_definitions: [],
                        steps: [
                            {
                                id: 1,
                                d_order: 1,
                                title: 'Mounting manifold to the bracket',
                                description: [
                                    {
                                        id: 3,
                                        d_order: 1,
                                        type: 'html',
                                        content: '<p>TBD: Copy list content here</p>'
                                    }
                                ],
                                reference_media: [
                                    {
                                        id: 1,
                                        url: 'https://via.placeholder.com/150'
                                    }
                                ]
                            },
                            {
                                id: 2,
                                d_order: 2,
                                title: 'Mounting ECU to the bracket',
                                description: [
                                    {
                                        id: 4,
                                        d_order: 1,
                                        type: 'html',
                                        content: '<ol><li>TBD; Copy in the steps</li></ol>'
                                    }
                                ],
                                reference_media: [
                                    {
                                        id: 1,
                                        url: 'https://via.placeholder.com/150'
                                    }
                                ]
                            },
                            {
                                id: 3,
                                d_order: 3,
                                title: 'Mounting PDM (Main Harness) to the bracket',
                                description: [
                                    {
                                        id: 6,
                                        d_order: 1,
                                        type: 'html',
                                        content: '<ol><li>TBD: Copy in the steps...</li></ol>'
                                    }
                                ],
                                reference_media: [
                                    {
                                        id: 6,
                                        url: 'https://via.placeholder.com/150'
                                    }
                                ]
                            },
                            {
                                id: 4,
                                d_order: 4,
                                title: 'Mounting the main harness to the manifold',
                                description: [
                                    {
                                        id: 6,
                                        d_order: 1,
                                        type: 'html',
                                        content: '<ol><li>TBD: Copy in the steps...</li></ol>'
                                    }
                                ],
                                reference_media: [
                                    {
                                        id: 6,
                                        url: 'https://via.placeholder.com/150'
                                    }
                                ]
                            },
                            {
                                id: 4,
                                d_order: 4,
                                title: 'Manifold Harness: Connection Check',
                                description: [
                                    {
                                        id: 6,
                                        d_order: 1,
                                        type: 'html',
                                        content: '<ol><li>TBD: Copy in the steps...</li></ol>'
                                    }
                                ],
                                reference_media: [
                                    {
                                        id: 6,
                                        url: 'https://via.placeholder.com/150'
                                    }
                                ]
                            }
                        ]
                    }
                ]
            }
        ]
    }
]
