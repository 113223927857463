import React from 'react'
import { withRouterProps } from '../../utils/with-router-props'
import { connect } from 'react-redux'
import { createSelector } from '@reduxjs/toolkit'

import EditableMarkdown from '../../utils/editable-markdown'
import { callApi, manageApiData } from '../../../actions/api'
import { apiResourceEndpoint } from '../../../constants/routes'
// import { buildRoutePath, routes } from '../../../constants/routes'
// import { Link } from 'react-router-dom'

const selectManualId = (state, manualId) => manualId
const selectGroups = state => state.manualGroups
const selectSteps = state => state.manualSteps
const selectSubsteps = state => state.manualSubsteps

const makeGroups = createSelector(
    [selectManualId, selectGroups],
    (manualId, groups) => {
        return groups.filter(group => group.manualId === manualId).sort((a, b) => a.dOrder - b.dOrder)
    }
)

const makeSteps = createSelector(
    [selectManualId, selectSteps],
    (manualId, steps) => {
        return steps.filter(step => step.manualId === manualId).sort((a, b) => a.dOrder - b.dOrder)
    }
)

const makeSubsteps = createSelector(
    [selectManualId, selectSubsteps],
    (manualId, substeps) => {
        return substeps.filter(substep => substep.manualId === manualId).sort((a, b) => a.dOrder - b.dOrder)
    }
)

const mapStateToProps = (state, ownProps) => {
    const manualId = Number(ownProps.params.manualId)
    const versionId = ownProps.params.versionId
    return {
        manualId: manualId,
        manual: state.manuals.find(manual => manual.id === manualId && manual.version === versionId),
        manualGroups: makeGroups(state, manualId),
        manualSteps: makeSteps(state, manualId),
        manualSubsteps: makeSubsteps(state, manualId)
    }
}

const ManualHeader = ({ dispatch, manualId, manual, manualGroups, manualSteps, manualSubsteps }) => {
    const handleSave = (toClipboard = false) => {
        // TODO: probably should move this to a utility? but it's only used here so maybe not
        let newManual = JSON.parse(JSON.stringify(manual))
        let newGroups = JSON.parse(JSON.stringify(manualGroups))
        let newSteps = JSON.parse(JSON.stringify(manualSteps))
        let newSubsteps = JSON.parse(JSON.stringify(manualSubsteps))
        newSubsteps.forEach(substep => {
            let parentStep = newSteps.find(step => step.id === substep.stepId)
            if (parentStep) {
                parentStep._related = parentStep._related || {}
                parentStep._related.substeps = parentStep._related.substeps || []
                parentStep._related.substeps.push(substep)
            } else {
                console.log('No parent step found for substep', substep)
            }
        })
        newSteps.forEach(step => {
            let parentGroup = newGroups.find(group => group.id === step.groupId)
            if (parentGroup) {
                parentGroup._related = parentGroup._related || {}
                parentGroup._related.steps = parentGroup._related.steps || []
                parentGroup._related.steps.push(step)
            } else {
                console.log('No parent group found for step', step)
            }
        })
        newManual.groups = newGroups
        if (toClipboard) {
            // console.log(newManual)
            navigator.clipboard.writeText(JSON.stringify(newManual, null, 2))
            alert('Copied to clipboard')
        } else {
            dispatch(callApi(apiResourceEndpoint('admin/manuals', 'UPDATE', manualId), { title: newManual.title, content: newManual.groups }))
        }
    }

    const handleCloning = () => {
        alert('clone function coming soon')
    }

    if (!manual) {
        return (
            <div>
                Loading the manual...
            </div>
        )
    }

    const saveManualTitle = (newTitle) => {
        const data = { ...manual, title: newTitle }
        dispatch(manageApiData(data))
    }

    return (
        <div className="manual-header">
            <h1><EditableMarkdown contentValue={manual.title} saveFX={saveManualTitle} /></h1>
            <div className="manual-header-actions">
                <span className="btn" onClick={() => handleSave(true)}>
                    JSON
                </span>
                <span className="btn" onClick={handleCloning}>
                    Clone
                </span>
                <span className="btn" onClick={() => handleSave()}>
                    Save
                </span>
            </div>
        </div>
    )
}

export default withRouterProps(connect(mapStateToProps)(ManualHeader))
