import React from 'react'
import { connect } from 'react-redux'
import { createSelector } from '@reduxjs/toolkit'

import BuilderSidebarSubsteps from './builder-sidebar-substeps'
import EditableMarkdown from '../../utils/editable-markdown'
import BuilderPageContent from './builder-page-content'

import { apiRemoveAction, manageApiData } from '../../../actions/api'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const selectSubsteps = state => state.manualSubsteps
const selectActiveStepId = (state, stepId) => stepId

const makeSubsteps = createSelector(
    [selectSubsteps, selectActiveStepId],
    (substeps, stepId) => {
        return substeps.filter(substep => substep.stepId === stepId)
            .sort((a, b) => {
                if (a.dOrder > b.dOrder) {
                    return 1
                }
                return -1
            })
    }
)

const mapStateToProps = (state, ownProps) => {
    return {
        subSteps: makeSubsteps(state, ownProps.activeStep?.id)
    }
}

const BuilderStepLayout = ({ dispatch, manual, activeStep, subSteps }) => {
    const saveStepTitle = (newTitle) => {
        const stepData = { ...activeStep, title: newTitle }
        dispatch(manageApiData(stepData))
    }

    const handleDelete = (evt) => {
        evt.stopPropagation()
        dispatch(apiRemoveAction(activeStep))
    }

    const renderActiveStepHeader = () => {
        if (activeStep) {
            return (
                <div className="with-delete-btn">
                    <h2><EditableMarkdown key={activeStep.id} contentValue={activeStep.title} saveFX={saveStepTitle} /></h2>
                    <span className="delete-btn">
                        <span onClick={handleDelete}>
                            <FontAwesomeIcon icon="trash-alt" />
                        </span>
                    </span>
                </div>
            )
        }
        return <div>No Step Selected</div>
    }

    return (
        <div className="builder-content">
            <div className="builder-step-header">
                {renderActiveStepHeader()}
            </div>
            <div className="content-wrapper">
                <BuilderSidebarSubsteps manual={manual} activeStep={activeStep} subSteps={subSteps} />
                <BuilderPageContent />
            </div>
        </div>
    )
}

export default connect(mapStateToProps)(BuilderStepLayout)
