import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import SHSForm from '../../utils/shs-form'
import InputElement from '../../utils/input'
import { signInWithEmailAndPass } from '../../../actions/auth'
import { routes } from '../../../constants/routes'

const Login = ({ dispatch, fireauth }) => {
    const submitFX = (formData, formId) => {
        dispatch(signInWithEmailAndPass(fireauth, formData.email, formData.password, formId))
    }

    const successFX = () => {
        // nothing because handled by auth router
    }

    return (
        <div className="container">
            <div className="login-wrapper">
                <img src="/imgs/optimus-technologies.svg" alt="Optimus Technologies" />
                <SHSForm submitFX={submitFX} successFX={successFX} buttonLabel="Login" buttonDisabled={!fireauth ? true : false}>
                    <InputElement
                        type="email"
                        id="email"
                        placeholder="esquire@battier.com"
                        label="Email Address"
                        errorMessage="Please provide a valid email address"
                        required />
                    <InputElement
                        type="password"
                        id="password"
                        placeholder="A secret word or phrase"
                        label="Password"
                        errorMessage="Password is required to be a minimum of 6 characters"
                        required />
                    <div className="additional-links">
                    Trouble logging in?<br />
                        <Link to={routes.authPasswordHelp.path} className="">
                        Reset your password
                        </Link>
                    </div>
                </SHSForm>
            </div>
        </div>
    )
}

export default connect()(Login)
