import React, { useState, useEffect } from 'react'
import InputElement from '../../utils/input'

const VerifyPassword = ({ onValidationChange }) => {
    const [newPassword, setNewPassword] = useState('')
    const [confirmNewPassword, setConfirmNewPassword] = useState('')
    const [uppercase, setUppercase] = useState(false)
    const [lowercase, setLowercase] = useState(false)
    const [containsNum, setContainsNum] = useState(false)
    const [specChar, setSpecChar] = useState(false)
    const [charLength, setCharLength] = useState(false)
    const [matchPass, setMatchPass] = useState(false)
    const [allValid, setAllValid] = useState(false)

    // setting error messages

    const [uppercaseError, setUppercaseError] = useState('')
    const [lowercaseError, setLowercaseError] = useState('')
    const [containsNumError, setContainsNumError] = useState('')
    const [specCharError, setSpecCharError] = useState('')
    const [charLengthError, setCharLengthError] = useState('')
    const [matchPassError, setMatchPassError] = useState('')

    const errors = [uppercaseError, lowercaseError, containsNumError, specCharError, charLengthError, matchPassError]

    useEffect(() => {
        // rerenders on state change
        validatePassword()
    })

    useEffect(() => {
        // if validations pass
        if (uppercase && lowercase && containsNum && specChar && charLength && matchPass) {
            setAllValid(true)
        } else {
            setAllValid(false)
        }
        onValidationChange(allValid)
    }, [uppercase, lowercase, containsNum, specChar, charLength, matchPass, allValid, onValidationChange])

    const handlePasswordChange = (key, val) => {
        setNewPassword(val)
    }

    const handleConfirmPasswordChange = (key, val) => {
        setConfirmNewPassword(val)
    }

    const validatePassword = () => {
        // validations
        if (newPassword.toLowerCase() !== newPassword) {
            setUppercase(true)
            setUppercaseError('')
        } else {
            setUppercase(false)
            setUppercaseError('Must contain an uppercase letter (A-Z)')
        }

        if (newPassword.toUpperCase() !== newPassword) {
            setLowercase(true)
            setLowercaseError('')
        } else {
            setLowercase(false)
            setLowercaseError('Must contain a lowercase letter (a-z)')
        }

        if (/\d/.test(newPassword)) {
            setContainsNum(true)
            setContainsNumError('')
        } else {
            setContainsNum(false)
            setContainsNumError('Must contain a number (0-9)')
        }

        // enabling regex and overriding eslint for this component
        /* eslint-disable no-useless-escape */
        if (/[~`!#@$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/g.test(newPassword)) {
            setSpecChar(true)
            setSpecCharError('')
        } else {
            setSpecChar(false)
            setSpecCharError('Must contain a special character')
        }

        if (newPassword.length >= 8) {
            setCharLength(true)
            setCharLengthError('')
        } else {
            setCharLength(false)
            setCharLengthError('Must be 8 characters long')
        }

        // password match
        if (newPassword !== '' && newPassword === confirmNewPassword) {
            setMatchPass(true)
            setMatchPassError('')
        } else {
            setMatchPass(false)
            setMatchPassError('Passwords must match')
        }
    }

    return (
        <>
            <InputElement
                type="password"
                default=""
                id="password"
                label="Password"
                placeholder="Something secret only you know..."
                extraClass="flex-100"
                onChange={handlePasswordChange}
                required />
            <InputElement
                type="password"
                default=""
                id="confirmPassword"
                label="Confirm Password"
                placeholder="Confirm password"
                extraClass="flex-100"
                onChange={handleConfirmPasswordChange}
                required />
            <div className="validation-errors">
                {errors.map((error, i) => {
                    return <p key={i}>{error}</p>
                })}
            </div>
        </>
    )
}

export default VerifyPassword
