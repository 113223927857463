import React from 'react'
import { Route, Routes } from 'react-router-dom'

import { nestedRoutePath, routes } from '../../../constants/routes'

import CreateManual from './create'
import ListManuals from './list'
import ViewManual from './view'
// import ManualStep from './step'

const AdminManualsIndex = () => {
    return (
        <Routes>
            <Route path={nestedRoutePath(routes.adminManualsIndex.path, routes.adminManualsCreate.path)} element={<CreateManual />} />
            {/* <Route path={nestedRoutePath(routes.adminManualsIndex.path, routes.adminManualsStepView.path)} element={<ManualStep />} /> */}
            <Route path={nestedRoutePath(routes.adminManualsIndex.path, routes.adminManualsSubstep.path)} element={<ViewManual />} />
            <Route path={nestedRoutePath(routes.adminManualsIndex.path, routes.adminManualsStep.path)} element={<ViewManual />} />
            <Route path={nestedRoutePath(routes.adminManualsIndex.path, routes.adminManualsView.path)} element={<ViewManual />} />
            <Route index element={<ListManuals />} />
        </Routes>
    )
}

export default AdminManualsIndex
