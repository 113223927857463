import React, { useState } from 'react'
import { connect } from 'react-redux'
import { withRouterProps } from '../../utils/with-router-props'

import { Link } from 'react-router-dom'

import { apiResourceEndpoint, buildRoutePath, routes } from '../../../constants/routes'
import { callApi } from '../../../actions/api'

import SHSForm from '../../utils/shs-form'
import InputElement from '../../utils/input'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const CreateManual = ({ dispatch, navigate }) => {
    const [ version, setVersion ] = useState('')
    const submitFX = (formData, formId) => {
        dispatch(callApi(apiResourceEndpoint('admin/manuals', 'CREATE'), formData, formId))
    }

    const successFX = (manualId) => {
        navigate(buildRoutePath(routes.adminManualBuilderIndex.path, { manualId: manualId, versionId: version }))
    }

    return (
        <div className="content">
            <div className="page-title">
                <Link to={routes.adminManualsIndex.path} className="icon-link">
                    <FontAwesomeIcon icon="circle-left" /> Back to Manuals
                </Link>
                <h1>Create Manual</h1>
            </div>
            <SHSForm submitFX={submitFX} successFX={successFX}>
                <InputElement
                    id="title"
                    label="title"
                    type="text"
                    placeholder="Title"
                    required />
                <InputElement
                    id="version"
                    label="version"
                    type="text"
                    placeholder="Version"
                    onChange={(id, val) => setVersion(val)}
                    required />
                <InputElement
                    id="internalReference"
                    label="Internal Reference"
                    type="text"
                    placeholder="Internal Reference"
                    required />
            </SHSForm>
        </div>
    )
}

export default withRouterProps(connect()(CreateManual))
