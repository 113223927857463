import { createSlice } from '@reduxjs/toolkit'
// import * as helpers from './helpers'

const INITIAL_STATE = {
    showSidebar: false
}

const uiSlice = createSlice({
    name: 'ui',
    initialState: INITIAL_STATE,
    reducers: {
        saveUiState(state, action) {
            state[action.payload.key] = action.payload.value
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase('KILL_STATE', (state, action) => {
                return INITIAL_STATE
            })
    }
})
export const { saveUiState } = uiSlice.actions
export default uiSlice.reducer
