import React from 'react'
import { connect } from 'react-redux'
import { createSelector } from '@reduxjs/toolkit'
import { withRouterProps } from '../../utils/with-router-props'
import { buildRoutePath, routes } from '../../../constants/routes'
import { Link } from 'react-router-dom'

const selectManualId = (state, manualId) => manualId
const selectGroups = state => state.manualGroups
const selectSteps = state => state.manualSteps

const makeGroups = createSelector(
    [selectManualId, selectGroups],
    (manualId, groups) => {
        return groups.filter(group => group.manualId === manualId).sort((a, b) => a.dOrder - b.dOrder)
    }
)

const makeSteps = createSelector(
    [selectManualId, selectSteps],
    (manualId, steps) => {
        return steps.filter(step => step.manualId === manualId).sort((a, b) => a.dOrder - b.dOrder)
    }
)

const mapStateToProps = (state, ownProps) => {
    const manualId = ownProps.params.manualId
    const versionId = ownProps.params.versionId
    return {
        activeStep: ownProps.params.stepId,
        activeSubstep: ownProps.params.substepId,
        manualId: manualId,
        manual: state.manuals.find(manual => manual.id === manualId && manual.version === Number(versionId)),
        manualGroups: makeGroups(state, manualId),
        manualSteps: makeSteps(state, manualId)
    }
}

const ManualSidebar = ({ activeStep, manual, manualGroups, manualSteps }) => {
    // TODO: Update links to point to the viewer not the builder
    if (!manual) {
        return <div>No Manual Found.</div>
    }

    const renderSidebar = () => {
        return manualGroups.map(group => {
            return (
                <div key={group.id} className="manual-group-wrapper">
                    <div className="group-wrapper">
                        <div className="group-label">{ group.title }</div>
                        { renderSidebarSteps(group.id) }
                    </div>
                </div>
            )
        })
    }

    const renderSidebarSteps = (groupId) => {
        return manualSteps.filter(step => step.groupId === groupId).map(step => {
            return (
                <div key={step.id} className={`step-item ${step.id === activeStep ? 'active' : ''}`}>
                    <div className="step-details">
                        <Link
                            key={step.id}
                            className="step-label"
                            to={
                                buildRoutePath(routes.adminManualsStep.path, {
                                    manualId: manual.id,
                                    versionId: manual.version,
                                    stepId: step.id })}>
                            { step.title }
                        </Link>
                    </div>
                </div>
            )
        })
    }

    return (
        <div className="step-list-wrapper">
            <div className="step-list-header">
                <div className="step-title">
                    { manual.title}
                </div>
                <div className="step-subtitle">
                    Version: { manual.version }
                </div>
            </div>
            <div className="step-list">
                {renderSidebar()}
            </div>
        </div>
    )
}

export default withRouterProps(connect(mapStateToProps)(ManualSidebar))
