import React from 'react'
import { connect } from 'react-redux'
import { Routes, Route } from 'react-router-dom'
import { withRouterProps } from '../../utils/with-router-props'

import AppUserView from './view'


const AppUserIndex = () => {
    return (
        <>
            <Routes>
                <Route index element={<AppUserView />} />
            </Routes>
        </>
    )
}

export default withRouterProps(connect()(AppUserIndex))
