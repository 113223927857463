import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { createSelector } from '@reduxjs/toolkit'
import { withRouterProps } from '../../utils/with-router-props'

import { apiResourceEndpoint, buildRoutePath, routes } from '../../../constants/routes'
import { fetchApiDataIfNeeded } from '../../../actions/api'
import { Link } from 'react-router-dom'
import { PaginationWrapper } from 'shs-pagination'
import moment from 'moment'

const selectTeamPaginator = state => state.paginator.teams
const selectTeams = state => state.teams
const makeTeams = createSelector(
    [selectTeams, selectTeamPaginator],
    (teams, paginator) => {
        if (paginator) {
            return teams
                .filter(field => paginator.itemIds.indexOf(field.id) !== -1)
                .sort((a, b) => {
                    if (paginator.itemIds.indexOf(a.id) > paginator.itemIds.indexOf(b.id)) {
                        return 1
                    }
                    return -1
                })
        }
        return []
    }
)

const mapStateToProps = (state, ownProps) => {
    const paginator = state.paginator.teams
    const teams = makeTeams(state)
    return { paginator, teams, roles: state.roles, account: state.account }
}

const TeamList = ({ dispatch, paginator, teams, roles, account }) => {
    const accountId = account.id
    useEffect(() => {
        dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('teams', 'LIST')))
        dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('roles', 'LIST')))
    }, [dispatch])

    const renderTeamRow = (team, index) => {
        const role = roles.find(r => r.id === team.roleId)
        return (
            <tr key={team.id}>
                <td className="index mobile-hide">{index}.</td>
                <td className="main">
                    <Link to={buildRoutePath(routes.appUserView.path, { accountId, userId: team.id })}>
                        {team.firstName} {team.lastName}
                    </Link>
                </td>
                <td className="main">{team.email}</td>
                <td className="main">{role.name}</td>
                <td className="main mobile-hide">{moment(team.createdAt).format('MMM Do \'YY h:mm a')}</td>
            </tr>
        )
    }

    const handlePaginationUpdate = (paginationParams) => {
        dispatch(fetchApiDataIfNeeded(apiResourceEndpoint(`teams`, 'LIST'), paginationParams))
    }

    const renderTeam = () => {
        if (!paginator) {
            return null
        }
        let allTeams = []
        teams.forEach((team, idx) => {
            let index = idx + ((paginator.currentPage - 1) * paginator.perPage) + 1
            allTeams.push(renderTeamRow(team, index))
        })
        return (
            <PaginationWrapper
                paginator={paginator}
                controls={{ search: { enabled: true, collapsed: true } }}
                callbackFX={handlePaginationUpdate}>
                <table className="table striped">
                    <thead>
                        <tr>
                            <th className="mobile-hide">#</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Role</th>
                            <th className="mobile-hide">Created</th>
                        </tr>
                    </thead>
                    <tbody>
                        {allTeams}
                    </tbody>
                </table>
            </PaginationWrapper>
        )
    }

    return (
        <>
            <div className="content">
                <div className="container">
                    <div className="team-wrapper">
                        <h2>My Team</h2>
                        <Link to={buildRoutePath(routes.appCreateUser.path, { accountId })} className="clickable">
                                    + Create User
                        </Link>
                        { renderTeam() }
                    </div>
                </div>
            </div>
        </>
    )
}

export default withRouterProps(connect(mapStateToProps)(TeamList))
