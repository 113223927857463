import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { createSelector } from '@reduxjs/toolkit'
import { withRouterProps } from '../../utils/with-router-props'

import BuilderManualGroups from './builder-manual-groups'
import BuilderStepLayout from './builder-step-layout'
import { fetchApiDataIfNeeded } from '../../../actions/api'
import { apiResourceEndpoint } from '../../../constants/routes'

const selectManualId = (state, manualId) => manualId
const selectGroups = state => state.manualGroups
const selectSteps = state => state.manualSteps

const makeGroups = createSelector(
    [selectManualId, selectGroups],
    (manualId, groups) => {
        return groups.filter(group => group.manualId === manualId).sort((a, b) => a.dOrder - b.dOrder)
    }
)

const makeSteps = createSelector(
    [selectManualId, selectSteps],
    (manualId, steps) => {
        return steps.filter(step => step.manualId === manualId).sort((a, b) => a.dOrder - b.dOrder)
    }
)

const mapStateToProps = (state, ownProps) => {
    const manualId = Number(ownProps.params.manualId)
    const versionId = ownProps.params.versionId
    // TODO: How to get the version steps to match the manual version?
    return {
        activeStep: state.manualSteps.find(step => step.id === ownProps.params.stepId),
        manual: state.manuals.find(manual => manual.id === Number(manualId) && manual.version === versionId),
        manualGroups: makeGroups(state, manualId),
        manualSteps: makeSteps(state, manualId)
    }
}

const AdminManualBuilderPage = ({ dispatch, activeStep, manual, manualGroups, manualSteps, params }) => {
    const manualId = params.manualId
    useEffect(() => {
        if (manualId) {
            dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('admin/manuals', 'VIEW', manualId)))
        }
    }, [dispatch, manualId])

    if (!manual) {
        return <>loading</>
    }

    return (
        <div className="manual-wrapper">
            <BuilderManualGroups manual={manual} groups={manualGroups} steps={manualSteps} />
            <BuilderStepLayout manual={manual} activeStep={activeStep} />
        </div>
    )
}

export default withRouterProps(connect(mapStateToProps)(AdminManualBuilderPage))
