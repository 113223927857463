import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { withRouterProps } from '../../utils/with-router-props'

import { manageApiData } from '../../../actions/api'
import { buildRoutePath, routes } from '../../../constants/routes'

import { ulid } from 'ulid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const mapStateToProps = (state, ownProps) => {
    return {
        activeSubstep: state.manualSubsteps.find(substep => substep.id === ownProps.params.substepId)
    }
}

const BuilderSidebarSubsteps = ({ dispatch, manual, activeStep, subSteps, activeSubstep }) => {
    const addManualSubstep = (stepId) => {
        let newSubtep = {
            id: ulid().toLowerCase(),
            manualId: manual.id,
            stepId: activeStep.id,
            dOrder: subSteps.length + 1,
            _key: 'MANUAL_SUBSTEPS',
            title: 'New Substep: Untitled'
        }
        dispatch(manageApiData(newSubtep))
    }

    const renderSubsteps = () => {
        let allSubsteps = []
        subSteps.forEach(substep => {
            const substepLink = buildRoutePath(routes.adminManualBuilderSubstep.path, { manualId: manual.id, versionId: manual.version, stepId: activeStep.id, substepId: substep.id })
            allSubsteps.push(
                <Link key={substep.id}
                    to={substepLink}
                    className={`step-item ${substep.id === activeSubstep?.id ? 'active' : ''}`}>
                    <span className="step-label">
                        {substep.title}
                    </span>
                </Link>
            )
        })
        return (
            <div className="step-list-wrapper">
                <div className="step-list">
                    {allSubsteps}
                    <div className="btn small dashed" onClick={() => addManualSubstep(activeStep.id)} >
                        <FontAwesomeIcon icon="plus" /> Add Substep
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="builder-sidebar builder-substeps">
            {renderSubsteps()}
            {/* <hr />
            <pre>
                Print list of Substeps.<br />
                Substeps can be drag / dropped to reorder<br />
            </pre> */}
        </div>
    )
}

export default withRouterProps(connect(mapStateToProps)(BuilderSidebarSubsteps))
