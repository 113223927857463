import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Route, Routes } from 'react-router-dom'
import { nestedRoutePath, routes } from '../../constants/routes'

import Dashboard from './dashboard'
import TruckView from './view-truck'
import InstallationView from './installation/view'
import AppUserIndex from './user/index'
import TeamIndex from './team/index'

import Pusher from 'pusher-js'
// import { manageApiData } from '../../actions/api'
import { connectToPusherWebsocket } from '../../actions/auth'
import Echo from 'laravel-echo'
import AppNav from '../navs/app-nav'
window.Pusher = Pusher

const mapStateToProps = (state) => {
    return {
        account: state.account
    }
}

const AppIndex = ({ fireauth, account }) => {
    useEffect(() => {
        const fetchAuthToken = async () => {
            let authToken = await connectToPusherWebsocket(fireauth)
            window.Echo = new Echo({
                broadcaster: 'reverb',
                key: process.env.REACT_APP_REVERB_APP_KEY,
                wsHost: process.env.REACT_APP_REVERB_HOST,
                wsPort: process.env.REACT_APP_REVERB_PORT,
                authEndpoint: `${process.env.REACT_APP_API_URL}/reverb-broadcasting/`,
                auth: { headers: authToken },
                wsPath: '/ws',
                wssPort: process.env.REACT_APP_REVERB_SSL_PORT ?? 443,
                forceTLS: (process.env.REACT_APP_REVERB_SCHEME ?? 'https') === 'https',
                enabledTransports: ['ws', 'wss']
            })

            window.Echo.private('channel-' + account.id)
                .listen('.private-ws-payload', async (data) => {})
            // potential listeners
            window.Echo.connector.pusher.connection.bind('connected', (data) => {})
            window.Echo.connector.pusher.connection.bind('connecting', async (data) => {
                authToken = await connectToPusherWebsocket(fireauth)
                window.Echo.options.auth = { headers: authToken }
            })
            window.Echo.connector.pusher.connection.bind('unavailable', (data) => {})
            window.Echo.connector.pusher.connection.bind('failed', (data) => {})
            window.Echo.connector.pusher.connection.bind('disconnected', async (data) => {
                alert('Your session has gone stale. Please refresh the page to continue.')
            })
        }
        if (account && fireauth) {
            fetchAuthToken()
        }

        return () => {
            window?.Echo.leave(`channel-${account.id}`)
        }
    }, [account, fireauth])

    return (
        <div id="app">
            <AppNav />
            <Routes>
                <Route path={ nestedRoutePath(routes.appIndex.path, routes.appUserIndex.path, true)} element={ <AppUserIndex /> } />
                <Route path={nestedRoutePath(routes.appIndex.path, routes.appTeamIndex.path, true)} element={ <TeamIndex /> } />
                <Route path={nestedRoutePath(routes.appIndex.path, routes.appTrucksView.path, true)} element={<TruckView />} />
                <Route path={nestedRoutePath(routes.appIndex.path, routes.appInstallationView.path, true)} element={<InstallationView />} />
                <Route index element={<Dashboard />} />
            </Routes>
        </div>
    )
}

export default connect(mapStateToProps)(AppIndex)
