import React from 'react'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { saveUiState } from '../../../reducers/ui'

const StepList = ({ dispatch, truck }) => {
    // TODO: We could disable this on 'not' mobile?
    const hideTruckSidebar = () => {
        dispatch(saveUiState({ key: 'showSidebar', value: false }))
    }

    const renderSteps = () => {
        const steps = truck.steps
        return (
            <>
                {steps.map((step, idx) => {
                    return (
                        <div key={idx} className="step-item" onClick={hideTruckSidebar}>
                            <div className="step-details">
                                <div className="step-count">
                                    Step {idx + 1}
                                </div>
                                <div className="step-label">
                                    {step.topic}
                                </div>
                                <div className="step-status">
                                    {step.complete === step.sectionTotal && <FontAwesomeIcon className="good" icon="circle-check" />}
                                </div>
                            </div>
                            {step.complete !== step.sectionTotal &&
                                <div className="step-action">
                                    {step.hasError ? (
                                        <div className="error">
                                            <FontAwesomeIcon icon="circle-xmark" />
                                            <span>{step.status}</span>
                                        </div>
                                    ) : (
                                        <div className={
                                            step.complete === 0 && idx > 0 && steps[idx - 1].complete >= 0
                                                ? 'disabled'
                                                : ''
                                        }>
                                            <FontAwesomeIcon icon="circle-right" />
                                            {step.complete === 0 ? <span>Start</span> : <span>Continue</span>}
                                        </div>
                                    )}
                                </div>
                            }
                        </div>
                    )
                })}
            </>
        )
    }

    return (
        <div className="step-list-wrapper">
            <div className="step-list-header">
                <div className="step-title">
                    XYV123
                </div>
                {/* TODO, Show groups... */}
                <div className="progress-bars">
                    <div className="progress-bar" style={{ width: '30%' }}>
                        <div className="progress-fill" style={{ width: '10%' }} />
                    </div>
                    <div className="progress-bar" style={{ width: '50%' }}>
                        <div className="progress-fill" style={{ width: '10%' }} />
                    </div>
                    <div className="progress-bar" style={{ width: '20%' }}>
                        <div className="progress-fill" style={{ width: '10%' }} />
                    </div>
                </div>
                <div className="progress-label">
                    X of Y Steps Complete
                </div>
            </div>
            <div className="step-list">
                {renderSteps()}
            </div>
        </div>
    )
}

export default connect()(StepList)
