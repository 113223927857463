import React, { useState } from 'react'
import { connect } from 'react-redux'
import { withRouterProps } from '../../utils/with-router-props'
import { Link } from 'react-router-dom'

import SHSForm from '../../utils/shs-form'
import InputElement from '../../utils/input'

import { generatePasswordResetLink } from '../../../actions/auth'
import { routes } from '../../../constants/routes'

const PasswordHelp = ({ dispatch, fireauth, location }) => {
    const [linkSent, setLinkSent] = useState(false)
    const isWelcomePage = location.pathname.indexOf('welcome') !== -1 ? true : false

    const successFX = () => {
        setLinkSent(true)
    }

    const submitFX = (formData, formId) => {
        dispatch(generatePasswordResetLink(fireauth, formData.email, formId))
    }

    return (
        <div className="login-wrapper">
            <img src="/imgs/optimus-technologies.svg" alt="Optimus Technologies" />
            { isWelcomePage
                ? <>
                    <h1>Welcome</h1>
                    { !linkSent &&
                                <p>Please enter your email address and we will send you a link to set your password.</p>
                    }
                </>
                : <h1>Password Help</h1>
            }
            { linkSent
                ? <div className="additional-links">
                    <p>Check your email, we sent you a link to help reset your password.</p>
                    <Link to={routes.authLogin.path} className="sm-txt">
                    Return to login
                    </Link>
                </div>

                : (
                    <SHSForm submitFX={submitFX} successFX={successFX} buttonLabel="Submit" buttonDisabled={!fireauth ? true : false}>
                        <InputElement
                            type="email"
                            id="email"
                            label="Email Address"
                            placeholder="user@example.com"
                            errorMessage="Please provide a valid email address"
                            extraClass="flex-100"
                            required />
                        <div className="additional-links">
                            <Link to={routes.authLogin.path} className="sm-txt">
                    Return to login
                            </Link>
                        </div>
                    </SHSForm>
                )
            }
        </div>
    )
}

export default withRouterProps(connect()(PasswordHelp))
