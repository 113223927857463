import React from 'react'
import { Link } from 'react-router-dom'

import { routes } from '../../constants/routes'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { withRouterProps } from '../utils/with-router-props'


const mapStateToProps = (state, ownProps) => {
    return {
        truck: state.trucks.find((truck) => truck.id === Number(ownProps.params.truckId))
    }
}

const ViewTruck = ({ dispatch, navigate, truck }) => {
    const renderCards = () => {
        const steps = truck.steps
        return (
            <div className="truck-card-list">
                {steps.map((step, idx) => {
                    return (
                        <div key={idx} className="card">
                            <div className="title">
                                Step {idx + 1}
                            </div>
                            <div className="status-block">
                                {step.topic}
                            </div>
                            <div className="status">
                                {step.complete === step.sectionTotal && <FontAwesomeIcon icon="circle-check" />}
                            </div>
                            {step.complete !== step.sectionTotal &&
                                <div className="step-wrapper">
                                    <div className="step-list" style={{ width: '100%' }}>
                                        <div className="step">
                                            <div className="progress" style={{ width: `${(step.complete / step.sectionTotal) * 100}%` }} />
                                        </div>
                                        {step.hasError ? (
                                            <>
                                                <FontAwesomeIcon style={{ color: '#C31D21' }} icon="circle-xmark" />
                                                <span style={{ color: '#C31D21' }}>{step.status}</span>
                                            </>
                                        ) : (
                                            <>
                                                <FontAwesomeIcon icon="circle-right" className={
                                                    step.complete === 0 && idx > 0 && steps[idx - 1].complete >= 0
                                                        ? 'disabled'
                                                        : ''
                                                } />
                                                {step.complete === 0 ? <span className={
                                                    step.complete === 0 && idx > 0 && steps[idx - 1].complete >= 0
                                                        ? 'disabled'
                                                        : ''
                                                }>Start</span> : <span>Continue</span>}
                                            </>
                                        )}
                                    </div>
                                </div>
                            }
                        </div>
                    )
                })}
            </div>
        )
    }

    if (!truck) {
        return <div>No truck data available</div>
    }

    return (
        <div className="content">
            <div className="page-title">
                <Link to={routes.appIndex.path} className="icon-link">
                    <FontAwesomeIcon icon="circle-left" />
                </Link>
                <span>Overview</span>
                <h1>{truck.name}</h1>
            </div>
            <div className="step-wrapper" style={{ marginTop: '3rem', justifyContent: 'center' }}>
                <div className="step-list" style={{ width: '100%', alignItems: 'center' }}>
                    <div className="step">
                        <div className="progress" style={{ width: `${(truck.currentStep / truck.totalSteps) * 100}%` }} />
                    </div>
                    <h3 style={{ display: 'flex', justifyContent: 'center' }}>{truck.currentStep} of {truck.totalSteps} Steps Complete</h3>
                </div>
            </div>
            {renderCards()}
        </div>
    )
}

export default withRouterProps(connect(mapStateToProps)(ViewTruck))
