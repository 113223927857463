import React from 'react'
import { Routes, Route } from 'react-router-dom'

import { routes } from '../../constants/routes'

// route components
import AuthHandler from './auth/auth-handler'
import Login from './auth/login'
import Logout from './auth/logout'
import PasswordHelp from './auth/password-help'
import PasswordReset from './auth/password-reset'
// pages
import HomePage from './home'
import NotFound from './not-found'
// layout elements
// import PublicNav from '../navs/public-nav'


const PublicIndex = ({ fireauth }) => {
    return (
        <div id="public">
            {/* <PublicNav /> */}
            <Routes>
                <Route path={routes.authLogin.path} element={<Login fireauth={fireauth} />} />
                <Route path={routes.authLogout.path} element={<Logout fireauth={fireauth} />} />
                <Route path={routes.authHandler.path} element={<AuthHandler fireauth={fireauth} />} />
                <Route path={routes.authPasswordHelp.path} element={<PasswordHelp fireauth={fireauth} />} />
                <Route path={routes.authPasswordReset.path} element={<PasswordReset fireauth={fireauth} />} />
                <Route path={routes.publicIndex.path} element={<HomePage />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </div>
    )
}

export default PublicIndex
