import { library } from '@fortawesome/fontawesome-svg-core'

import {
    faChevronDown, faTimes, faCircle, faEye, faPen, faArrowLeft, faArrowRight, faCircleXmark, faCircleCheck, faPlus, faCircleExclamation, faMagnifyingGlass, faHeart, faSliders, faUser, faUsers, faArchive, faEllipsisV, faSlidersH, faList,
    faMapMarkerAlt, faClipboardList, faProjectDiagram, faExpand, faCompress, faSortUp, faSortDown, faSort, faPaperPlane, faCar, faAngleLeft, faAngleRight, faCheck, faAddressCard, faTicket, faImages,
    faFile, faFileAlt, faCog, faPersonDotsFromLine, faSlash, faHandSparkles, faSignOut, faBars, faFilter, faCircleLeft, faCircleRight, faTrashAlt
} from '@fortawesome/free-solid-svg-icons'

// import {
//     faDotCircle as farDotCircle, faCircle as farCircle, faCalendar as farCalendar, faTrashCan as farTrashCan, faCircleXmark as farCircleXmark, faBell as farBell, faHeart as farHeart, faCalendarAlt, faStickyNote, faClone, faFilePdf
// } from '@fortawesome/free-regular-svg-icons'

library.add(
    faChevronDown, faTimes, faCircle, faEye, faPen, faArrowLeft, faArrowRight, faCircleXmark, faCircleCheck, faPlus, faCircleExclamation, faMagnifyingGlass, faHeart, faSliders, faUser, faUsers, faArchive, faEllipsisV, faSlidersH, faList,
    faMapMarkerAlt, faClipboardList, faProjectDiagram, faExpand, faCompress, faSortUp, faSort, faSortDown, faPaperPlane, faCar, faAngleLeft, faAngleRight, faCheck, faAddressCard, faTicket, faImages,
    faFile, faFileAlt, faCog, faPersonDotsFromLine, faSlash, faHandSparkles, faSignOut, faBars, faFilter, faCircleLeft, faCircleRight, faTrashAlt
)
