import React, { useRef } from 'react'

import ContentEditable from 'react-contenteditable'
import sanitizeHtml from 'sanitize-html'


const EditableMarkdown = ({ contentValue, saveFX }) => {
    const activeContent = useRef(contentValue ? contentValue : '')

    const sanitizeConf = {
        allowedTags: ['strong', 'em', 'b', 'i', 'a', 'u', 'ul', 'ol', 'li', 'p', 'br'],
        allowedAttributes: { a: ['href'] }
    }
    const handleChange = (evt) => {
        const sanitizedContent = sanitizeHtml(evt.target.value, sanitizeConf)
        activeContent.current = sanitizedContent
    }

    const handleBlur = () => {
        saveFX(activeContent.current)
    }

    return (
        <div className="editable-content">
            <ContentEditable
                html={activeContent.current}
                onChange={handleChange}
                onBlur={handleBlur} />
        </div>
    )
}

export default EditableMarkdown
