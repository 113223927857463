import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { routes } from '../../constants/routes'

const AdminDashboard = () => {
    const [width, setWidth] = useState(window.innerWidth)

    function handleWindowSizeChange() {
        setWidth(window.innerWidth)
    }

    // testing different layouts based on screen size

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange)
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange)
        }
    }, [])

    return (
        <div className="container">
            { (width < 992) ? <h1>Smaller than a laptop</h1> : <h1>Larger than a breadbox</h1> }
            <Link to={routes.adminManualsIndex.path}>Manuals</Link>
        </div>
    )
}

export default AdminDashboard
