import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { nestedRoutePath, routes } from '../../../constants/routes'

import ManualSidebar from './manual-sidebar'
import ManualStep from './step'

const ViewManual = () => {
    return (
        <div className="sidebar-layout manual-view content-wrapper">
            <div className="sidebar">
                <div className="sidebar-wrapper container">
                    <ManualSidebar />
                </div>
            </div>
            <Routes>
                {/* <Route path={nestedRoutePath(routes.adminManualBuilderIndex.path, routes.adminManualBuilderSubstep.path)} element={<Substep />} /> */}
                <Route path={nestedRoutePath(routes.adminManualsView.path, routes.adminManualsStep.path)} element={<ManualStep />} />
                <Route index element={<>No Active Step / Substep ?</>} />
            </Routes>
        </div>
    )
}

export default ViewManual
