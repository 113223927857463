import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { withRouterProps } from '../utils/with-router-props'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { buildRoutePath, routes } from '../../constants/routes'
// import PageHeader from '../shared/layout/page-header'

const mapStateToProps = (state) => {
    return {
        truckData: state.trucks
    }
}

const Dashboard = ({ dispatch, truckData }) => {
    const renderProgress = (steps) => {
        let allSteps = []
        const totalSteps = steps.reduce((acc, step) => acc + step.sectionTotal, 0)
        steps.forEach((step, idx) => {
            allSteps.push(
                <div key={idx} className="progress-bar" style={{ width: `${(step.sectionTotal / totalSteps) * 100}%` }}>
                    <div className="progress-fill" style={{ width: `${(step.complete / step.sectionTotal) * 100}%` }} />
                </div>
            )
        })
        return (
            <div className="progress-bars">
                {allSteps}
            </div>
        )
    }

    const renderCards = () => {
        return (
            <div className="card-grid">
                {truckData.map((card, idx) => {
                    return (
                        <Link
                            to={buildRoutePath(routes.appInstallationView.path, { truckId: card.id })}
                            className="card"
                            key={card.id}>
                            <div className="title">
                                {card.name}
                            </div>
                            <div className="status-block">
                                <span className={`status ${card.hasError ? 'error' : 'good'}`}>{card.status}</span>
                                {renderProgress(card.steps)}
                                <div className="progress-label">
                                    {card.currentStep} of {card.totalSteps} Steps Complete
                                </div>
                            </div>
                        </Link>
                    )
                })}
                <Link to={buildRoutePath(routes.appTrucksView.path, { truckId: 'abc' })} className="card add-item mobile-hide" key="add-item">
                    <div className="title">Start a new Truck</div>
                </Link>
            </div>
        )
    }
    return (
        <div className="content-wrapper">
            <div className="content">
                <div className="container">
                    <div className="dashboard-wrapper">
                        {renderCards()}
                    </div>
                    <div className="footer-btns mobile-show">
                        <div className="btn blue action">
                            <FontAwesomeIcon icon="filter" />
                        </div>
                        <div className="btn blue main">
                            Start New Truck
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouterProps(connect(mapStateToProps)(Dashboard))
