import React from 'react'
import { connect } from 'react-redux'
import { withRouterProps } from '../../utils/with-router-props'

const mapStateToProps = (state, ownProps) => {
    return {
        manual: state.manuals.find(manual => manual.id === ownProps.params.manualId && manual.version === Number(ownProps.params.versionId))
    }
}

const ManualStep = ({ manual }) => {
    if (!manual) {
        return null
    }

    return (
        <div>
            {manual.title}
        </div>
    )
}

export default withRouterProps(connect(mapStateToProps)(ManualStep))
