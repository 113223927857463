import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { withRouterProps } from '../../utils/with-router-props'
import { getAuth } from 'firebase/auth'
import { callApi, fetchApiDataIfNeeded } from '../../../actions/api'
import { generatePasswordResetLink } from '../../../actions/auth'
import { apiResourceEndpoint, buildRoutePath, routes } from '../../../constants/routes'
import EditableDisplayField from '../../utils/editable-display-field'
import SHSForm from '../../utils/shs-form'
import InputElement from '../../utils/input'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'

const mapStateToProps = (state, ownProps) => {
    return {
        roles: state.roles,
        user: state.teams.find(u => u.id === Number(ownProps.params.userId)),
        account: state.account
    }
}

const UserView = ({ dispatch, params, navigate, user, roles, account }) => {
    const [shouldRedirect, setShouldRedirect] = useState(false)
    const [linkSent, setLinkSent] = useState(false)
    const fireauth = getAuth()
    const userId = params.userId
    const prevUser = usePrevious(user)

    useEffect(() => {
        if (prevUser && !user) {
            setShouldRedirect(true)
        }
    }, [user, prevUser])

    function usePrevious(value) {
        const ref = useRef()
        useEffect(() => {
            ref.current = value
        }, [value])
        return ref.current
    }

    useEffect(() => {
        if (userId) {
            dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('teams', 'VIEW', userId)))
        }
    }, [dispatch, userId])

    useEffect(() => {
        dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('roles', 'LIST')))
    }, [dispatch])

    if (shouldRedirect) {
        navigate(buildRoutePath(routes.appTeamIndex.path, { accountId: account.id }))
    }

    if (!user) {
        return null
    }

    const roleOptions = roles.slice()
        .sort((a, b) => {
            if (a.level > b.level) {
                return -1
            }
            return 1
        })
        .map(role => ({ value: role.id, label: role.name }))

    const handleDelete = () => {
        if (window.confirm('Are you sure you want to delete this user?')) {
            dispatch(callApi(apiResourceEndpoint('teams', 'DELETE', userId), {}, -1))
        }
    }

    const successFX = () => {
        setLinkSent(true)
    }

    const submitFX = (formData, formId) => {
        if (fireauth && formData && formData.email) {
            dispatch(generatePasswordResetLink(fireauth, formData.email, formId))
        } else {
            console.error('fireauth or formData.email is undefined')
        }
    }

    return (
        <div className="content">
            <div>
                <p>Manage the user fields below</p>
                <h3>User Info</h3>
                <EditableDisplayField
                    label="First Name"
                    display={user.firstName}
                    endpoint="teams"
                    dataObj={user}
                    fields={[{
                        field: 'firstName',
                        type: 'text',
                        required: true
                    }]} />
            </div>
            <EditableDisplayField
                label="Last Name"
                display={user.lastName}
                endpoint="teams"
                dataObj={user}
                fields={[{
                    field: 'lastName',
                    type: 'text',
                    required: true
                }]} />
            <EditableDisplayField
                label="Email"
                display={user.email}
                endpoint="teams"
                dataObj={user}
                fields={[{
                    field: 'email',
                    type: 'text',
                    required: true
                }]} />
            <EditableDisplayField
                label="Role"
                display={user._computed.roleName}
                endpoint="teams"
                dataObj={user}
                fields={[{
                    field: 'roleId',
                    type: 'valueSelect',
                    options: roleOptions,
                    required: true
                }]} />
            <p className="clickable mt-5 sm-txt" onClick={handleDelete}>
                <FontAwesomeIcon icon="trash-alt" /> DELETE USER
            </p>
            <p className="mt-5 sm-txt">
                Users can set up their password by visiting <Link to={routes.authWelcome.path} className="sm-txt">{process.env.REACT_APP_WEBAPP_URL}welcome/</Link>
            </p>
            {!linkSent ?
                <SHSForm submitFX={submitFX} successFX={successFX} buttonLabel="Send Password Reset Link" buttonClass="black center">
                    <InputElement
                        type="hidden"
                        default={user.email}
                        id="email"
                        required />
                </SHSForm>
                : <p className="mt-5 sm-txt">Password reset link sent to {user.email}</p>
            }

            <div className="box mt-5">
                { user.lastLogin
                    ? <>
                        <h3>Auth Details</h3>
                        <p>
                            <span className="p-label">Last Login</span><br />
                            {moment(user.lastLogin).format('ddd. MMM. Do Y, hh:mm a')}<br />
                            <span className="sm-txt">{moment(user.lastLogin).fromNow()}</span>
                        </p>
                        <p>
                            <span className="p-label">Last Token Refresh</span><br />
                            {moment(user.lastRefresh).format('ddd. MMM. Do Y, hh:mm a')}<br />
                            <span className="sm-txt">{moment(user.lastRefresh).fromNow()}</span>
                        </p>
                        <p>
                            <span className="p-label">Last Time Password Updated</span><br />
                            {moment(user.passwordUpdated).format('ddd. MMMM. Do Y, hh:mm a')}<br />
                            <span className="sm-txt">{moment(user.passwordUpdated).fromNow()}</span>
                        </p>
                    </>
                    : <p>User has never logged in</p>
                }
            </div>
        </div>
    )
}

export default withRouterProps(connect(mapStateToProps)(UserView))
