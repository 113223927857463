import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Routes, Route, Navigate } from 'react-router-dom'
import { withRouterProps } from './utils/with-router-props'
import { routes } from '../constants/routes'

import DevTools from './utils/dev-tools'

import AdminIndex from './admin/index'
import AppIndex from './app/index'
import AuthRouter from './public/auth/auth-router'
import PublicIndex from './public/index'

import { isEmpty } from 'lodash'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { handleFirebaseAuth, killState } from '../actions/auth'
import { removeAuth } from '../reducers/auth'

import './utils/icons'

import '../assets/scss/main.scss'

const ROUTER_PAGES = [routes.publicIndex.path, routes.authLogin.path, routes.router.path, routes.authRegister.path, routes.authPasswordReset.path]

function AppRoute({ children, auth, location }) {
    let isAuthenticated = (!isEmpty(auth) && !auth.isAnonymous) || false
    return isAuthenticated ? children : <Navigate to={routes.authLogin.path} state={{ from: location }} />
}

function AdminRoute({ children, auth, user, location }) {
    let isAuthenticated = (!isEmpty(auth) && !auth.isAnonymous) || false
    let isAdmin = (!isEmpty(user) && user?._computed?.roleLevel >= 1000) || false
    return isAuthenticated && isAdmin ? children : <Navigate to={routes.authLogin.path} state={{ from: location }} />
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        user: state.user
    }
}

const App = ({ dispatch, auth, user, location, firebaseApp, navigate }) => {
    const [hasAuthRouted, setHasAuthRouted] = useState(false)
    const [shouldRoute, setShouldRoute] = useState(false)
    const [ fireauth, setFireauth ] = useState(null)
    const pathname = location.pathname

    useEffect(() => {
        const fbAuth = getAuth(firebaseApp)
        setFireauth(fbAuth)
        const unsubscribe = onAuthStateChanged(fbAuth, (firebaseUser) => {
            if (firebaseUser) {
                dispatch(handleFirebaseAuth(firebaseUser.toJSON()))
            } else {
                setHasAuthRouted(false)
                dispatch(killState())
                dispatch(removeAuth())
            }
        })
        return () => unsubscribe()
    }, [dispatch, firebaseApp])

    useEffect(() => {
        if (!hasAuthRouted && !isEmpty(auth)) {
            if (ROUTER_PAGES.indexOf(pathname) !== -1 || pathname.indexOf('/app/') !== -1) {
                setHasAuthRouted(true)
                setShouldRoute(true)
            }
        }
    }, [hasAuthRouted, auth, pathname])

    useEffect(() => {
        if (shouldRoute) {
            setShouldRoute(false)
            const fromState = location?.state?.from?.pathname ? location.state.from.pathname : null
            navigate(routes.router.path, { state: { from: { pathname: fromState } } })
        }
    }, [shouldRoute, location.state, navigate])

    return (
        <div id="main">
            <Routes>
                <Route path={routes.router.path} element={<AppRoute auth={auth} location={location}><AuthRouter /></AppRoute>} />
                <Route path={`${routes.appIndex.path}*`} element={<AppRoute auth={auth} location={location}><AppIndex fireauth={fireauth} /></AppRoute>} />
                <Route path={`${routes.adminIndex.path}*`} element={<AdminRoute auth={auth} user={user} location={location}><AdminIndex fireauth={fireauth} /></AdminRoute>} />
                <Route path="*" element={<PublicIndex fireauth={fireauth} />} />
            </Routes>
            <DevTools />
        </div>
    )
}

export default withRouterProps(connect(mapStateToProps)(App))
