import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { manageApiData } from '../../../actions/api'
import { buildRoutePath, routes } from '../../../constants/routes'
import { withRouterProps } from '../../utils/with-router-props'

import { ulid } from 'ulid'

import EditableMarkdown from '../../utils/editable-markdown'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const mapStateToProps = (state, ownProps) => {
    return {
        activeStep: state.manualSteps.find(step => step.id === ownProps.params.stepId)
    }
}

const BuilderManualGroups = ({ dispatch, manual, groups, steps, activeStep }) => {
    const saveGroupTitle = (group, newTitle) => {
        const data = { ...group, title: newTitle }
        dispatch(manageApiData(data))
    }

    const addManualStep = (parentId) => {
        let newStep = {
            id: ulid().toLowerCase(),
            manualId: manual.id,
            groupId: parentId,
            dOrder: steps.length + 1,
            _key: 'MANUAL_STEPS',
            title: 'New Step: Untitled'
        }
        dispatch(manageApiData(newStep))
    }

    const addManualGroup = () => {
        let newGroup = {
            id: ulid().toLowerCase(),
            manualId: manual.id,
            dOrder: groups.length + 1,
            _key: 'MANUAL_GROUPS',
            title: 'New Group: Untitled'
        }
        dispatch(manageApiData(newGroup))
    }

    const renderGroupSteps = (groupId) => {
        let allSteps = []
        steps.filter(step => step.groupId === groupId)
            .sort((a, b) => {
                if (a.dOrder > b.dOrder) {
                    return 1
                }
                return -1
            })
            .forEach(step => {
                const stepLink = buildRoutePath(routes.adminManualBuilderStep.path, { manualId: manual.id, versionId: manual.version, stepId: step.id })
                allSteps.push(
                    <Link key={step.id}
                        className={`step-item ${step.id === activeStep?.id ? 'active' : ''}`}
                        to={stepLink}>
                        <div className="step-label">{step.title}</div>
                    </Link>
                )
            })
        return allSteps
    }

    const renderGroups = () => {
        let allGroups = []
        groups.forEach(group => {
            allGroups.push(
                <div key={group.id} className="group-wrapper">
                    <div className="group-header">
                        <EditableMarkdown contentValue={group.title} saveFX={(newTitle) => saveGroupTitle(group, newTitle)} />
                    </div>
                    {renderGroupSteps(group.id)}
                    <span className="btn small dashed" onClick={() => addManualStep(group.id)}>
                        <FontAwesomeIcon icon="plus" /> Add Step
                    </span>
                </div>
            )
        })
        return allGroups
    }

    return (
        <div className="builder-sidebar builder-steps">
            {renderGroups()}
            <div className="add-group" onClick={addManualGroup}>
                Add Group
            </div>
            {/* <hr />
            <pre>
                Steps can be drag / dropped to reorder<br />
                TBD: Can I move steps from group to group?<br />
            </pre> */}
        </div>
    )
}

export default withRouterProps(connect(mapStateToProps)(BuilderManualGroups))
