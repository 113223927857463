import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'

import { withRouterProps } from '../../utils/with-router-props'
import { getAuth } from 'firebase/auth'
import { fetchApiDataIfNeeded } from '../../../actions/api'
import { apiResourceEndpoint, buildRoutePath, routes } from '../../../constants/routes'
import EditableDisplayField from '../../utils/editable-display-field'
import ManageAuth from './manage-auth'

const selectUser = state => state.user

const mapStateToProps = (state) => {
    const user = selectUser(state)
    return {
        user
    }
}

const AppUserView = ({ dispatch, params, navigate, user }) => {
    const [shouldRedirect, setShouldRedirect] = useState(false)
    const fireauth = getAuth()
    const userId = params.userId
    const prevUser = usePrevious(user)


    useEffect(() => {
        if (prevUser && !user) {
            setShouldRedirect(true)
        }
    }, [user, prevUser])

    function usePrevious(value) {
        const ref = useRef()
        useEffect(() => {
            ref.current = value
        }, [value])
        return ref.current
    }

    useEffect(() => {
        if (userId) {
            dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('user', 'VIEW')))
        }
    }, [dispatch, userId])

    if (shouldRedirect) {
        navigate(buildRoutePath(routes.appIndex.path))
    }

    if (!user) {
        return null
    }

    return (
        <>
            <div className="content">
                <div className="container">
                    <div className="profile-wrapper">
                        <h2>My Profile</h2>
                        <h3>Click on field to edit</h3>
                        <EditableDisplayField
                            label="First Name"
                            display={user.firstName}
                            endpoint="user"
                            dataObj={user}
                            fields={[{
                                field: 'firstName',
                                type: 'text',
                                required: true
                            }]} />
                        <EditableDisplayField
                            label="Last Name"
                            display={user.lastName}
                            endpoint="user"
                            dataObj={user}
                            fields={[{
                                field: 'lastName',
                                type: 'text',
                                required: true
                            }]} />
                        <EditableDisplayField
                            label="Phone"
                            display={user.phone}
                            endpoint="user"
                            dataObj={user}
                            fields={[{
                                field: 'phone',
                                type: 'text',
                                required: true
                            }]} />
                        <ManageAuth fireauth={fireauth} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default withRouterProps(connect(mapStateToProps)(AppUserView))
