// import firebase from 'firebase/app'
import { upsertForm } from '../reducers/form'
import { formStates } from '../constants/helper-states'

import { signOut, signInWithEmailAndPassword, sendPasswordResetEmail, verifyPasswordResetCode, confirmPasswordReset, EmailAuthProvider, reauthenticateWithCredential, updatePassword } from 'firebase/auth'
import { saveAuth } from '../reducers/auth'
import { saveAuthHandler, saveAuthHandlerError } from '../reducers/auth-code'
// const fireauth = getAuth()

export const killState = () => {
    return {
        type: `KILL_STATE`
    }
}

export const saveAnonymousAuth = (auth) => {
    return {
        type: `SAVE_ANONYMOUS_AUTH`,
        auth
    }
}

export const removeAuth = () => {
    return {
        type: `REMOVE_AUTH`
    }
}

export const connectToPusherWebsocket = (fireauth) => {
    return fireauth.currentUser.getIdToken()
        .then(token => {
            return {
                Authorization: `Bearer ${token}`
            }
        }).catch(err => {
            console.log('unable to get authorization info')
        })
}

export const handleFirebaseAuthCode = (auth, code, action) => {
    return dispatch => {
        verifyPasswordResetCode(auth, code)
            .then(email => {
                dispatch(saveAuthHandler({ code, action, email, isAnonymous: false }))
            })
            .catch(err => {
                dispatch(saveAuthHandlerError())
                console.log('got an error...', err)
            })
    }
}

export const anonymousSignIn = (fireauth, fetchingId) => {
    return dispatch => {
        fireauth.signInAnonymously()
            .then(() => {
                // dispatch(upsertFetching(fetchingId, fetchingStates.COMPLETE))
            })
            .catch(err => {
                console.log('error with anonymous')
            })
    }
}

export const signInWithEmailAndPass = (fireauth, email, password, formId) => {
    return dispatch => {
        signInWithEmailAndPassword(fireauth, email, password)
            .then(() => {
                dispatch(upsertForm({ id: formId, status: formStates.SUCCESS }))
            })
            .catch(err => {
                dispatch(upsertForm({ id: formId, status: formStates.ERROR, body: `Error: ${err.message}` }))
            })
    }
}

export const generatePasswordResetLink = (fireauth, email, formId) => {
    return dispatch => {
        sendPasswordResetEmail(fireauth, email)
            .then(() => {
                dispatch(upsertForm({ id: formId, status: formStates.SUCCESS }))
            })
            .catch(err => {
                dispatch(upsertForm({ id: formId, status: formStates.ERROR, body: `Error: ${err.message}` }))
            })
    }
}

export const resetPasswordWithCode = (auth, code, email, password, formId) => {
    return dispatch => {
        return confirmPasswordReset(auth, code, password)
            .then(() => {
                signInWithEmailAndPassword(auth, email, password)
                    .then(() => {
                        dispatch(upsertForm(formId, formStates.SUCCESS))
                    })
                    .catch(err => {
                        console.log('in here with an error?', err)
                    })
            })
            .catch(err => {
                console.log('in here?', err)
                dispatch(upsertForm(formId, formStates.ERROR, `Error: ${err.message}`))
            })
    }
}

export const updateAuthPassword = (fireauth, formData, formId) => {
    return dispatch => {
        if (formData.password !== formData.confirmPassword) {
            dispatch(upsertForm({ id: formId, status: formStates.ERROR, body: `Passwords Don't Match` }))
            return false
        }
        const user = fireauth.currentUser
        const credential = EmailAuthProvider.credential(user.email, formData.existingPassword)
        return reauthenticateWithCredential(user, credential)
            .then(() => {
                updatePassword(user, formData.password)
                    .then(() => {
                        dispatch(upsertForm({ id: formId, status: formStates.SUCCESS }))
                    })
                    .catch(err => {
                        dispatch(upsertForm({ id: formId, status: formStates.ERROR, body: `Error: ${err.message}` }))
                    })
            })
            .catch(err => {
                dispatch(upsertForm({ id: formId, status: formStates.ERROR, body: `Error: ${err.message}` }))
            })
    }
}

export const handleFirebaseAuth = (firebaseUser) => {
    return dispatch => {
        dispatch(saveAuth(firebaseUser))
    }
}

export const handleSignOut = (fireauth) => {
    return dispatch => {
        signOut(fireauth)
            .then(() => {
                console.log('signed out')
                dispatch(killState())
            })
            .catch(err => {
                // TODO: global message declaring we can't do that...
                console.log(err)
            })
    }
}
