import { getAuth } from 'firebase/auth'
import { API_METHODS, buildRoutePath } from '../constants/routes'
import { formStates } from '../constants/helper-states'
import { upsertForm } from '../reducers/form'
import { saveApiCall } from '../reducers/api-cache'
import { savePaginator } from '../reducers/pager'

import isEqual from 'lodash/isEqual'
import camelCase from 'lodash/camelCase'

import moment from 'moment'


const API_URL = process.env.REACT_APP_API_URL

const apiAction = (type, data) => {
    console.log(type, data)
    return { type, data }
}

const apiListAction = (data) => {
    if (data.length > 0 && '_key' in data[0]) {
        // if (data.length > 0) {
        // return { type: 'facilities/genericSingleAction', data }
        // return { type: `SAVE_${data[0]._key}_LIST`, data }
        return { type: `${data[0]._key.toUpperCase()}/listAction`, data }
    }
    return { type: null }
}

const apiSingleAction = (data) => {
    if (data && '_key' in data) {
        const actionType = `${data._key.toUpperCase()}/singleAction`
        return { type: actionType, data }
    }
    return { type: null }
}

export const apiRemoveAction = (data) => {
    if (data && '_key' in data) {
        const actionType = `${data._key.toUpperCase()}/removeItem`
        return { type: actionType, data }
        // return { type: `REMOVE_${data._key}`, data }
    }
    return { type: null }
}

const checkForObjEquality = (data, updateObj) => {
    if (!('_key' in data)) {
        return updateObj
    }
    let key = data._key
    let dataCopy = { ...data }
    let objCopy = { ...updateObj }
    delete dataCopy._related
    if (key in objCopy) {
        let match = false
        objCopy[key].forEach(keyData => {
            if (match) {
                return
            }
            // remove the related from the objs
            if (isEqual(keyData, dataCopy)) {
                match = true
                return
            }
        })
        if (!match) {
            objCopy[key].push(dataCopy)
        }
    } else {
        objCopy[key] = [dataCopy]
    }
    return objCopy
}

// const apiGenericListAction = (data, dataKey) => {
//     // if (data.length > 0 && '_key' in data[0]) {
//     if (data.length > 0) {
//         return { type: `${dataKey}/listAction`, data }
//     }
//     return { type: null }
// }

// const apiGenericObjAction = (data, dataKey) => {
//     return { type: `${dataKey}/singleAction`, data }
// }

// const manageGenericData = (data, dataKey) => {
//     return dispatch => {
//         if (Array.isArray(data)) {
//             dispatch(apiGenericListAction(data, dataKey))
//         } else {
//             dispatch(apiGenericObjAction(data, dataKey))
//         }
//     }
// }

const managePagination = (paginator) => {
    return dispatch => {
        if ('_key' in paginator) {
            const pagerKey = camelCase(paginator._key)
            delete paginator._key
            dispatch(savePaginator({
                key: pagerKey,
                data: paginator
            }))
        }
    }
}

export const manageApiData = (data, updateObj = {}, depth = 0) => {
    let uObj = { ...updateObj }
    if (!data || (Array.isArray(data) && data.length === 0)) {
        if (depth === 0) {
            return dispatch => {
                return { type: null }
            }
        }
        return updateObj
    } else if (Array.isArray(data)) {
        data.forEach(item => {
            if (item && '_related' in item) {
                Object.keys(item._related).forEach(relation => {
                    let relatedObj = manageApiData(item._related[relation], uObj, depth + 1)
                    uObj = { ...relatedObj }
                })
            }
            let myObj = checkForObjEquality(item, uObj)
            uObj = { ...myObj }
        })
        if (depth > 0) {
            return uObj
        }
    } else {
        if ('_related' in data) {
            Object.keys(data._related).forEach(relation => {
                let relatedObj = manageApiData(data._related[relation], uObj, depth + 1)
                uObj = { ...relatedObj }
            })
        }

        let myObj = checkForObjEquality(data, uObj)
        uObj = { ...myObj }
        if (depth > 0) {
            return uObj
        }
    }
    if (depth === 0) {
        return dispatch => {
            Object.keys(uObj).forEach(updateKey => {
                if (uObj[updateKey].length === 1) {
                    dispatch(apiSingleAction(uObj[updateKey][0]))
                } else {
                    dispatch(apiListAction(uObj[updateKey]))
                }
            })
        }
    }
    return uObj
}

export const fakeActionCall = (action, data) => {
    return dispatch => {
        dispatch(apiAction(action, data))
    }
}

const sendToApi = (headers, routeObj, data, formId, directReturn, isFile = false) => {
    return async dispatch => {
        let fetchParams = { headers, method: routeObj.method }
        let query = ''
        if (routeObj.method === API_METHODS.GET) {
            query = Object.keys(data).map(key => {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }).join('&')
            if (query !== '') {
                query = `?${query}`
            }
        } else if (routeObj.method === API_METHODS.POST) {
            fetchParams.body = JSON.stringify(data)
        }
        let apiRoute = buildRoutePath(routeObj.url, routeObj.params)
        const apiUrl = `${API_URL}${apiRoute}${query}`
        return fetch(apiUrl, fetchParams)
            .then(response => {
                if (isFile) {
                    return Promise.all([response, response.blob()])
                }
                return Promise.all([response, response.json()])
            })
            .then(([response, result]) => {
                if (response.ok) {
                    if (isFile) {
                        let dispositionParts = response.headers.get('content-disposition').split(';')
                        let filename = dispositionParts[1].trim().replace('filename=', '')
                        const url = window.URL.createObjectURL(new Blob([result]))
                        const link = document.createElement('a')
                        link.href = url
                        link.setAttribute('download', filename)
                        document.body.appendChild(link)
                        link.click()
                        link.parentNode.removeChild(link)
                        // dispatch({ type: 'FILE_DOWNLOADED' })
                        dispatch(upsertForm({ id: formId, status: formStates.SUCCESS, body: null }))
                    } else {
                        if (routeObj.method === API_METHODS.DELETE) {
                            dispatch(apiRemoveAction(result.data))
                        } else if (directReturn) {
                            dispatch(upsertForm({ id: formId, status: formStates.SUCCESS, body: result.data.id }))
                            return result.data
                        } else {
                            dispatch(manageApiData(result.data))
                            if ('paginator' in result && result.paginator) {
                                dispatch(managePagination(result.paginator))
                            }
                        }
                        dispatch(upsertForm({ id: formId, status: formStates.SUCCESS, body: result.data.id }))
                    }
                    return 'SUCCESS'
                }
                if (result.code) {
                    throw new Error(`${result.errors}`)
                }
                // this should handle the responses from validation endpoints
                let msgs = []
                Object.keys(result).forEach(key => {
                    msgs.push(result[key].join(', '))
                })
                throw new Error(msgs.join(', '))
            })
            .catch(err => {
                // TODO: need to log this?
                dispatch(upsertForm({ id: formId, status: formStates.ERROR, body: err.message }))
            })
    }
}

export const callApi = (routeObj, data, formId, directReturn = false, isFile = false) => {
    return dispatch => {
        dispatch(upsertForm({ id: formId, status: formStates.PROCESSING }))
        const fireauth = getAuth()
        return fireauth.currentUser.getIdToken()
            .then(token => {
                const headers = {
                    'Content-Type': 'application/json; charset=UTF-8',
                    'Authorization': `Bearer ${token}`
                }
                return dispatch(sendToApi(headers, routeObj, data, formId, directReturn, isFile))
            })
    }
}

export const callPublicApi = (routeObj, data, formId, directReturn = false) => {
    return dispatch => {
        dispatch(upsertForm({ id: formId, status: formStates.PROCESSING }))
        const headers = {
            'Content-Type': 'application/json; charset=UTF-8'
        }
        dispatch(sendToApi(headers, routeObj, data, formId, directReturn))
    }
}

export const callFileApi = (routeObj, data, formId) => {
    return dispatch => {
        dispatch(upsertForm({ id: formId, status: formStates.PROCESSING }))
        const auth = getAuth()
        return auth.currentUser.getIdToken()
            .then(token => {
                const fetchParams = {
                    headers: {
                        Authorization: `Bearer ${token}`
                    },
                    method: 'POST',
                    body: data
                }
                let apiRoute = buildRoutePath(routeObj.url, routeObj.params)
                const apiUrl = `${API_URL}${apiRoute}`
                return fetch(apiUrl, fetchParams)
            })
            .then(response => {
                return Promise.all([response, response.json()])
            })
            .then(([response, result]) => {
                if (response.ok) {
                    dispatch(upsertForm({ id: formId, status: formStates.SUCCESS, body: result.data.id }))
                    dispatch(manageApiData(result.data))
                } else {
                    throw new Error(`${result.code}: ${result.errors}`)
                }
            })
            .catch(err => {
                console.log('error in file upload')
                dispatch(upsertForm({ id: formId, status: formStates.ERROR, error: err.message }))
            })
    }
}

const buildURLParams = (params) => {
    let query = ''
    query = Object.keys(params).map(key => {
        if (Array.isArray(params[key])) {
            return params[key].map(ad => {
                return encodeURIComponent(key) + '[]=' + encodeURIComponent(ad)
            }).join('&')
        } else if (typeof params[key] === 'object') {
            return Object.keys(params[key]).map(nestedKey => {
                if (Array.isArray(params[key][nestedKey])) {
                    return params[key][nestedKey].map(nad => {
                        return encodeURIComponent(key) + `[${nestedKey}][]=` + encodeURIComponent(nad)
                    }).join('&')
                }
                return encodeURIComponent(key) + `[${nestedKey}]=` + encodeURIComponent(params[key][nestedKey])
            }).join('&')
        }
        return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
    }).join('&')
    return query
}

const makeApiFetch = (routeUrl, params, fetchParams, force, directReturn) => {
    return dispatch => {
        if (!force) {
            dispatch(saveApiCall({ url: routeUrl, isFetching: true, hasError: false, params }))
        }
        let query = buildURLParams(params)
        if (query !== '') {
            query = `?${query}`
        }
        const apiUrl = `${API_URL}${routeUrl}${query}`
        return fetch(apiUrl, fetchParams)
            .then(response => {
                return Promise.all([response, response.json()])
            })
            .then(([response, result]) => {
                if (response.ok) {
                    if (!force) {
                        dispatch(saveApiCall({ url: routeUrl, isFetching: false, hasError: false, lastFetch: moment().format() }))
                    }
                    if (!directReturn) {
                        // dispatch({type: null})
                        dispatch(manageApiData(result.data))
                        if ('paginator' in result && result.paginator) {
                            dispatch(managePagination(result.paginator))
                        }
                    } else {
                        // return dispatch({type: null})
                        return result.data
                    }
                } else {
                    throw new Error(`${result.code}: ${result.errors}`)
                }
                return Promise.resolve()
            })
            .catch(err => {
                // TODO: need to log this?
                // dispatch(fetchError(routeUrl, err.message))
                dispatch(saveApiCall({ url: routeUrl, isFetching: false, hasError: true, error: err.message }))
            })
    }
}

// TODO: Need to be able to handle query paramters, like for pagination, etc.
const fetchApiData = (routeUrl, params, force, directReturn = false, isPublic = false) => {
    return dispatch => {
        // dispatch the isLoading....
        // dispatch(requestFetch(routeUrl, params))
        let fetchParams = {
            headers: { 'Content-Type': 'application/json; charset=UTF-8' },
            method: 'GET'
        }
        if (isPublic) {
            return dispatch(makeApiFetch(routeUrl, fetchParams, params, force, directReturn))
        }
        const fireauth = getAuth()
        return fireauth.currentUser.getIdToken()
            .then(token => {
                fetchParams.headers.Authorization = `Bearer ${token}`
                return dispatch(makeApiFetch(routeUrl, params, fetchParams, force, directReturn))
            })
            .catch(err => {
                // TODO: need to log this?
                // dispatch(fetchError(routeUrl, err.message))
                dispatch(saveApiCall({ url: routeUrl, isFetching: false, hasError: true, error: err.message }))
            })
    }
}

const shouldFetchApiData = (apiCaches, routeUrl, params) => {
    const cachedRoute = apiCaches.find(cache => cache.url === routeUrl && cache.params === params)
    if (!cachedRoute) {
        return true
    } else if (cachedRoute.isFetching) {
        return false
    }
    const lastFetch = moment(cachedRoute.lastFetch)
    const lapsedTime = moment().diff(lastFetch, 's', true)
    // TODO: make this an environment variable?
    if (lapsedTime < 5) {
        return false
    }
    return true
}

export const fetchApiDataIfNeeded = (routeObj, params = {}, force = false, directReturn = false) => {
    const routeUrl = buildRoutePath(routeObj.url, params)
    return (dispatch, getState) => {
        if (shouldFetchApiData(getState().apiCaches, routeUrl, params) || force) {
            return dispatch(fetchApiData(routeUrl, params, force, directReturn))
        }
        return false
    }
}

export const fetchPublicApiDataIfNeeded = (routeObj, params = {}, force = false, directReturn = false) => {
    const routeUrl = buildRoutePath(routeObj.url, params)
    return (dispatch, getState) => {
        if (shouldFetchApiData(getState().apiCaches, routeUrl, params) || force) {
            return dispatch(fetchApiData(routeUrl, params, force, directReturn, true))
        }
        return false
    }
}
