import React, { useState } from 'react'

import { connect } from 'react-redux'
import { withRouterProps } from '../../utils/with-router-props'
import PageHeader from '../../shared/layout/page-header'
import StepList from '../../shared/layout/step-list'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const mapStateToProps = (state, ownProps) => {
    return {
        truck: state.trucks.find((truck) => truck.id === Number(ownProps.params.truckId))
    }
}

const InstallationView = ({ truck }) => {
    const [showSidebar, setShowSidebar] = useState(true)

    const toggleSidebar = () => {
        setShowSidebar(!showSidebar)
    }

    if (!truck) {
        return <div>No truck data available</div>
    }

    let fakeStepDefinition = {
        label: 'Swap OEM Tank for Vector Fuel Tankfuel',
        totalSteps: 3,
        currentStep: 1,
        currentStepStatus: 'in-progress',
        currentStepStatusLabel: 'In Progress'
    }
    return (
        <div className={`sidebar-layout installation-view content-wrapper ${!showSidebar ? 'closed' : 'open'}`}>
            <div className="sidebar">
                <div className="sidebar-wrapper">
                    <StepList truck={truck} />
                    <div className="sidebar-control show-sidebar" onClick={toggleSidebar}>
                        <FontAwesomeIcon icon="angle-right" />
                    </div>
                    <div className="sidebar-control hide-sidebar" onClick={toggleSidebar}>
                        <FontAwesomeIcon icon="angle-left" />
                    </div>
                </div>
            </div>
            <div className="content">
                <PageHeader
                    title={fakeStepDefinition.label}
                    label={`Step ${fakeStepDefinition.currentStep} of ${fakeStepDefinition.totalSteps}`}
                    status={<div className="status">{fakeStepDefinition.currentStepStatusLabel}</div>} />
            </div>
        </div>
    )
}

export default withRouterProps(connect(mapStateToProps)(InstallationView))
