import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Link, Navigate } from 'react-router-dom'
import { withRouterProps } from '../../utils/with-router-props'
import { routes, apiResourceEndpoint } from '../../../constants/routes'
import { fetchApiDataIfNeeded } from '../../../actions/api'
import { isEmpty } from 'lodash'

const mapStateToProps = (state) => {
    return {
        account: state.account,
        auth: state.auth,
        role: state.role,
        user: state.user
    }
}

const AuthRouter = ({ dispatch, user, location }) => {
    const [readyToRoute, setReadyToRoute] = useState(false)
    const [timedOut, setTimedOut] = useState(false)
    // add a set timeout of 5 seconds to change the state and mark is as stale
    useEffect(() => {
        const timer = setTimeout(() => {
            setTimedOut(true)
        }, 5000)
        return () => clearTimeout(timer)
    }, [])

    useEffect(() => {
        if (isEmpty(user)) {
            dispatch(fetchApiDataIfNeeded(apiResourceEndpoint('user/load-details', 'LIST')))
        }
        if (user && '_computed' in user) {
            setReadyToRoute(true)
        }
    }, [dispatch, user])


    if (readyToRoute) {
        const accountRoleLevel = user._computed.roleLevel
        if (location.state && location.state.from.pathname) {
            return <Navigate to={location.state.from.pathname} />
        }
        // TODO: Check account type for where to send them, will have more later
        if (accountRoleLevel >= 1000) {
            return <Navigate to={routes.appIndex.path} />
        }
        return <Navigate to={routes.appIndex.path} />
    }
    if (timedOut) {
        <div>
            <p>Oops...something went wrong. Please return to the home page.</p>
            <Link to={routes.publicIndex.path}>Home</Link>
        </div>
    }
    return <div>Authorizing session...</div>
}

export default withRouterProps(connect(mapStateToProps)(AuthRouter))
